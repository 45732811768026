import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    TURN_AROUND_TIMES,
    LOCATION_TYPES,
    VALUE_UNITS,
    SERVICE_TYPES,
    RUN_TYPES,
    SUB_REQUEST_TYPES,
    BOUND_TYPES,
    SHIMPMENT_VALUES,
    CARGO_UNITS
} from 'Constants/commonConstants';
import ActionLoader from 'Components/ActionLoader';
import SearchDropdown from 'Components/SearchDropdown';
import Sticky from 'Components/Sticky';
import Button from 'Components/Button';
import Dropdown from 'Components/Dropdown';
import { ReactComponent as BackIcon } from '../AddTrip/icons/back.svg';
import { SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import RouteDetailForm from '../../../components/RouteDetailForm/RouteDetailForm';
import SecondaryRouteDetailForm from '../../../components/RouteDetailForm/SecondaryRouteDetailForm';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import Input from 'Components/Input';
import ReactTooltip from 'react-tooltip';
import Tabs from 'Components/Tabs';
import Checkbox from 'Components/Checkbox';
import { v4 as uuid4 } from 'uuid';
import moment from 'moment';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import {
    GET_ADD_TRIP_TEMPLATE, GET_LOCATIONS, ADD_TRIP, GET_GPS_VENDORS,
    GET_OPERATOR_TYPE, GET_VEHICLE_TYPES,GET_VEHICLE_BODY_TYPES, GET_VEHICLE_DETAILS
} from 'Constants/actionConstants';
import { UPLOAD_TRIPS, GET_PROVIDERS, GET_SEEKERS, GET_NUMBERS_OPERATOR_TYPE, GET_DO_NO_BASED_ON_FROM_LOC, GET_SCHEDULER_ROUTE_DETAILS } from 'Constants/actionConstants';
import UploadButton from 'Components/UploadButton';
import { scroll } from '../../../utils/common';
import { ReactComponent as CloseIcon } from '../../../components/TrackingSettings/icons/close-icon.svg';
import { GET_TAT_DETAILS } from 'Constants/actionConstants';
import { getUser, getCompanyType } from 'Utils/storage';
import { SEEKER, PROVIDER, GOOGLE_API_KEY, TTBIL } from 'Constants/commonConstants';
import TimePicker from 'rc-time-picker';

const serviceTypes = [...SERVICE_TYPES];
const runTypes = [...RUN_TYPES];
const subRequestTypes = [...SUB_REQUEST_TYPES];
const boundTypes = [...BOUND_TYPES];
const turnAroundTimes = [...TURN_AROUND_TIMES];
const typesOfLocation = [...LOCATION_TYPES];
const valueUnits = [...VALUE_UNITS];
const shipmentValue = [...SHIMPMENT_VALUES];
const emptyLocation = {
    "city" : "",
    "longitude" : 0,
    "location_type_name":"Type of location",
    "pincode" : "",
    "cut_off_time" : "6:30 PM",
    "weekly_off" : "Sunday",
    "state" : "",
    "country" : "",
    "latitude" : 0,
    "location_type" : "",
    "name": "",
    "notification_emails": [],
    "notification_numbers": [],
};
const emptyGeoFenceLocation = {
    location: "",
    latitude: 0,
    longitude: 0,
    geofence_radius: 1000
};
const emptyNotification = {
    "email": "",
    "sms": "",
    "id": 0
};
const emptyDriver = {
    // "name": "",
    "firstName": "",
    "lastName": "",
    "number": "",
    fromLocation: {
        place: '',
        latitude: 0,
        longitude: 0
    },
    toLocation: {
        place: '',
        latitude: 0,
        longitude: 0
    },
    operatorType: {},
    gpsVendor: {}
};

const CUSTOMER_TYPE_MAP = {
    1: "Denso",
    2: "Non Denso"
};

const CUSTOMER_TYPE = [
    {value: 1, name: CUSTOMER_TYPE_MAP[1]},
    {value: 2, name: CUSTOMER_TYPE_MAP[2]}
]

const userDetails = getUser();
const isSeeker =  getCompanyType() === SEEKER;


class SecondaryAddTrip extends React.Component {
    constructor() {
        super();
        this.state = {
            serviceType: serviceTypes[1],
            boundType: boundTypes[1],
            subRequestType: subRequestTypes[1],
            runType: runTypes[1],
            comments: "",
            startDate: this.calaculateStartDate(),
            di_time:  moment().format('HH:mm'),
            customer_type: 0,
            focused: false,
            turnAroundTime: 2,
            routeDetails: [],
            startLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "start" },
            endLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "end"},
            returnLocation: {},
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsFrom: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsTo: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            transporterName: isSeeker ? "" : userDetails.name,
            transporterEmail: isSeeker ? "" : userDetails.email,
            transporterContact: isSeeker ? "" : userDetails.contact_number,
            transporterPan: isSeeker ? "" : userDetails.pan_no,
            driverName: "",
            driverNo: "",
            driverDetails: [{ ...emptyDriver, id: uuid4() }],
            gpsVendors: {},
            vehicleBodyType: {
                id: 0,
                value: 0,
                name: ""
            },
            vehicleType: {
                id: 0,
                value: 0,
                name: ""
            },
            vehicleRegNo: "",
            trackingPreference: 2,
            headOfficeNotification:
                {
                    "plant_type": 1,
                    "plant_name": "Head Office",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            plantNotification:
                {
                    "plant_type": 2,
                    "plant_name": "Plant",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            depoNotification:
                {
                    "plant_type": 3,
                    "plant_name": "Depo",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            doNo: "",
            lrNo: "",
            invoiceNo: "",
            ewayBillNo: "",
            itemName: "",
            itemValue: "",
            highValue: 0,
            // valueUnit: {
            //     name: "— Select Units — ",
            //     value: 0
            // },
            cargoWeight: "",
            cargoUnit: [...CARGO_UNITS].filter((cargo) => { return cargo.value === 'MT' })[0],
            trackingPreferences: [
                {
                    "tracking_type": 1,
                    "tracking_name": "App Tracking",
                    "enabled": false
                },
                {
                    "tracking_type": 2,
                    "tracking_name": "SIM Tracking",
                    "enabled": (TTBIL.includes(userDetails.company_id) ? false : true)
                },
                {
                    "tracking_type": 3,
                    "tracking_name": (TTBIL.includes(userDetails.company_id) ? "GPS Tracking" : "Third party Tracking"),
                    "enabled": (TTBIL.includes(userDetails.company_id) ? true : false)
                },
                {
                    "tracking_type": 4,
                    "tracking_name": "Manual Tracking",
                    "enabled": false
                },
                {
                    "tracking_type": 5,
                    "tracking_name": "None",
                    "enabled": false
                }
            ],
            file: null,
            fromLoc: {},
            toLoc: {},
            searchList: [],
            tripData : {},
            filterScheduler: {route_code: '', source_plant_code: '', dest_plant_code: '', di_time: '', vehicle_type: '', customer_type: 0},
            isVehicleSelected: false
        };

        this.saveTrip = this.saveTrip.bind(this);
        this.getAddTripTemplate = this.getAddTripTemplate.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.uploadTrips = this.uploadTrips.bind(this);
        this.addLocation = this.addLocation.bind(this);
        this.handleRouteDataChange = this.handleRouteDataChange.bind(this);
        this.handleStartEndlocationChange = this.handleStartEndlocationChange.bind(this);
        this.handleGeofenseLocationChange = this.handleGeofenseLocationChange.bind(this);
        this.addGeofenceLocation = this.addGeofenceLocation.bind(this);
        this.addHeadOfficeAccount = this.addHeadOfficeAccount.bind(this);
        this.addPlantAccount = this.addPlantAccount.bind(this);
        this.addDepoAccount = this.addDepoAccount.bind(this);
        this.handleNotificationAccountChange = this.handleNotificationAccountChange.bind(this);
        this.removeLocation = this.removeLocation.bind(this);
        this.removeGeoFenceLocation = this.removeGeoFenceLocation.bind(this);
        this.addDriver = this.addDriver.bind(this);
        this.removeDriver = this.removeDriver.bind(this);
        this.handleDriverChange = this.handleDriverChange.bind(this);
    }
    calaculateStartDate=()=>{
        if(TTBIL.includes(userDetails.company_id)){
            return moment(new Date()).startOf('day')
        }
        return moment(new Date())
    }
    addDriver = () => {
        this.setState({
            driverDetails: this.state.driverDetails.concat([{
                ...emptyDriver,
                fromLocation: { place: '', latitude: 0, longitude: 0 },
                toLocation: { place: '', latitude: 0, longitude: 0},
                id: uuid4()
            }])
        });
    }

    removeDriver = (id) => {
        const newdriverDetails = this.state.driverDetails.filter((driver) => driver.id !== id);
        this.setState({driverDetails: newdriverDetails});
    }

    handleDriverChange = (field, value, id) => {
        const reg = new RegExp('^[0-9]+$');
        if (field === 'number' && value && !reg.test(value)) {
            return;
        }
        if (field === 'number' && value.length > 10) {
            this.setState({ error: false });
            return;
        }
        if (field === 'name' && value) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        const newDrivers = this.state.driverDetails.map((driver) => {
            if (driver.id !== id) {
                return driver;
            }

            return { ...driver, [field]: value };
        });
        this.setState({driverDetails: newDrivers});

        const tP = this.state.trackingPreferences.filter((pref)=> { return pref.enabled }) || [];

        if (field === 'number' && value.length === 10 && tP.length > 0 && tP[0].tracking_type === 2) {
            this.props.dispatch({
                type: GET_NUMBERS_OPERATOR_TYPE,
                params: {'number': value},
                onSuccess: ((data) => {
                    const { operatorTypes } = this.props;
                    const driverDetails = [...this.state.driverDetails];
                    const operatorSelected = operatorTypes.filter((op) => { return op.id === data.operator_type });

                    driverDetails.map((driver) => {
                        if (driver.id === id) {
                            driver.operatorType = (operatorSelected && operatorSelected.length > 0) ? operatorSelected[0] : {};
                        }
                    });

                    this.setState({
                        driverDetails
                    })
                })
            });
        }
    }

    selectGPSVendor = (gpsVendor) => {
        const gpsVendors = {
            id: gpsVendor.id,
            name: gpsVendor.name
        }
        this.setState({
            gpsVendors
        });
    }

    removeLocation = (id) => {
        const newRouteDetails = this.state.routeDetails.filter((route) => route.id !== id);
        this.setState({routeDetails: newRouteDetails});
    }

    removeGeoFenceLocation = (id) => {
        const newGeofenceLocations = this.state.geofenceLocations.filter((route) => route.id !== id);
        this.setState({geofenceLocations: newGeofenceLocations});
    }

    changePreference = (preferenceIndex) => {
        const newState = {...this.state};
        const trackingPreferences = newState.trackingPreferences;
        for (var i in trackingPreferences) {
            if (i == preferenceIndex) {
                trackingPreferences[i].enabled = true;
            } else {
                trackingPreferences[i].enabled = false;
            }
        }

        newState.saved = false;
        this.setState(newState);
    }

    addHeadOfficeAccount() {
        const currentAccounts = [...this.state.headOfficeNotification.accounts];
        const newHeadeNotification = {...this.state.headOfficeNotification};
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length});
        this.setState({headOfficeNotification: {...newHeadeNotification}});
    }

    removeHeadOfficeAccount(id) {
        const newHeadeNotification = {...this.state.headOfficeNotification};
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({headOfficeNotification: {...newHeadeNotification}});
    }

    addPlantAccount() {
        const currentAccounts = [...this.state.plantNotification.accounts];
        const newHeadeNotification = {...this.state.plantNotification};
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length});
        this.setState({plantNotification: {...newHeadeNotification}});
    }

    removePlantAccount(id) {
        let newHeadeNotification = {...this.state.plantNotification};
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({plantNotification: {...newHeadeNotification}});
    }

    addDepoAccount() {
        const currentAccounts = [...this.state.depoNotification.accounts];
        const newHeadeNotification = {...this.state.depoNotification};
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length});
        this.setState({depoNotification: {...newHeadeNotification}});
    }

    removeDepoAccount(id) {
        const newHeadeNotification = {...this.state.depoNotification };
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({depoNotification: {...newHeadeNotification}});
    }

    addLocation () {
        this.setState({routeDetails: this.state.routeDetails.concat([{...JSON.parse(JSON.stringify(emptyLocation)), id: uuid4()}])}, () => {
        });
    }

    addGeofenceLocation() {
        this.setState({geofenceLocations: this.state.geofenceLocations.concat([{...emptyGeoFenceLocation, id: uuid4()}])}, ()=>{
        });
    }

    // handleRouteDataChange(key, value, id) {
    //     if (typeof value === 'string' && key === 'location') {
    //         const newRouteDetails = this.state.routeDetails.map((route) => {
    //             if (route.id !== id) {
    //                 return route;
    //             }
    //             return { ...route, city: value };
    //         });

    //         this.setState({routeDetails: newRouteDetails});

    //         const { dispatch } = this.props;
    //         dispatch({
    //             type: GET_LOCATIONS,
    //             param: {
    //                 search: value,
    //                 location: 'destination'
    //             }
    //         });
    //     } else if (typeof value !== 'string' && key === 'location_type') {
    //         const newRouteDetails = this.state.routeDetails.map((route) => {
    //             if (route.id !== id) {
    //                 return route;
    //             }
    //             return { ...route, location: value.value, location_type_name: value.name };
    //         });
    //         this.setState({routeDetails: newRouteDetails});
    //     } else if (typeof value !== 'string' && key === 'weekly_off') {
    //         const newRouteDetails = this.state.routeDetails.map((route) => {
    //             if (route.id !== id) {
    //                 return route;
    //             }
    //             return { ...route,  weekly_off: value.value };
    //         });
    //         this.setState({routeDetails: newRouteDetails});
    //     } else if (typeof value === 'string'){
    //         const newRouteDetails = this.state.routeDetails.map((route) => {
    //             if (route.id !== id) {
    //                 return route;
    //             }
    //             return { ...route, [key]: value };
    //         });
    //         this.setState({routeDetails: newRouteDetails});
    //     } else {
    //         const newRouteDetails = this.state.routeDetails.map((route) => {
    //             if (route.id !== id) {
    //                 return route;
    //             }
    //             return { ...route, ...value };
    //         });
    //         this.setState({routeDetails: newRouteDetails});
    //     }
    // }

    // handleStartEndlocationChange(key, value, which) {
    //     if (typeof value === 'string' && key === 'location') {
    //         if (which === "start") {
    //             let startLocation = this.state.startLocation;
    //             if (value) {
    //                 startLocation.city = value;
    //                 startLocation.plant_name = value;
    //             } else {
    //                 startLocation =  {...emptyLocation, id: "start"}
    //             }
    //             this.setState({startLocation});
    //         }
    //         else if (which === 'end') {
    //             let endLocation = this.state.endLocation;
    //             if (value) {
    //                 endLocation.city = value;
    //                 endLocation.plant_name = value;
    //             } else {
    //                 endLocation = {...emptyLocation, id: "end"}
    //             }
    //             this.setState({endLocation});
    //         }
    //         const { dispatch } = this.props;
    //         dispatch({
    //             type: GET_LOCATIONS,
    //             param: {
    //                 search: value,
    //                 location: which === "start" ? 'source' : 'destination'
    //             }
    //         });
    //     } else if (typeof value === 'string') {
    //         if (which == "start") {
    //             this.setState({startLocation: {...this.state.startLocation, [key]:value}});
    //         }
    //         else {
    //             this.setState({endLocation: {...this.state.endLocation, [key]:value}});
    //         }
    //     } else if (typeof value !== 'string' && key === 'location_type') {
    //         if (which === "start") {
    //             this.setState({startLocation: {...this.state.startLocation, location_type:value.value, location_type_name: value.name}});
    //         } else {
    //             this.setState({endLocation: {...this.state.endLocation, location_type:value.value, location_type_name: value.name}});
    //         }
    //     } else if (typeof value !== 'string' && key === 'weekly_off') {
    //         if (which === "start") {
    //             this.setState({startLocation: {...this.state.startLocation, weekly_off: value.name}});
    //         } else {
    //             this.setState({endLocation: {...this.state.endLocation, weekly_off: value.name}});
    //         }
    //     } else {
    //         if (which === "start") {
    //             this.setState({startLocation: value}, () => {
    //                 const { dispatch } = this.props;
    //                 if(this.state.startLocation && TTBIL.includes(userDetails.company_id)) {
    //                     dispatch({
    //                         type: GET_DO_NO_BASED_ON_FROM_LOC,
    //                         params: {
    //                             branch_id: this.state.startLocation.branch_id,
    //                             start_date: this.state.startDate && this.state.startDate.valueOf()
    //                         },
    //                         onSuccess: (data) => {
    //                             if((data || {}).do_no) {
    //                                 this.setState({
    //                                     doNo: data.do_no
    //                                 })
    //                             }
    //                         }
    //                     })
    //                 }
    //                 if (this.state.endLocation.longitude && this.state.endLocation.latitude) {
    //                     const data = {
    //                         source_lat: this.state.startLocation.latitude,
    //                         source_lng: this.state.startLocation.longitude,
    //                         destination_lat: this.state.endLocation.latitude,
    //                         destination_lng: this.state.endLocation.longitude
    //                     };
    //                     if (!isSeeker) {
    //                         data.seeker_id = this.state.company_id
    //                     }
    //                     dispatch({
    //                         type: GET_TAT_DETAILS,
    //                         data
    //                     });
    //                 }
    //             });
    //         } else {
    //             this.setState({endLocation: value}, () => {
    //                 if (this.state.startLocation.longitude && this.state.startLocation.latitude) {
    //                     const { dispatch } = this.props;
    //                     const data = {
    //                         source_lat: this.state.startLocation.latitude,
    //                         source_lng: this.state.startLocation.longitude,
    //                         destination_lat: this.state.endLocation.latitude,
    //                         destination_lng: this.state.endLocation.longitude
    //                     };
    //                     if (!isSeeker) {
    //                         data.seeker_id = this.state.company_id
    //                     }
    //                     dispatch({
    //                         type: GET_TAT_DETAILS,
    //                         data
    //                     });
    //                 }
    //             });
    //         }
    //     }
    // }

    handleRouteDataChange(key, value, id, isClear) {
        if(typeof value === 'string' && key==='location') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                if(value) {
                    return { ...route, city: value };
                } else {
                    return { ...route, ...JSON.parse(JSON.stringify(emptyLocation)) };
                }
            });

            this.setState({routeDetails: newRouteDetails});

            const { dispatch } = this.props;
            dispatch({
                type: GET_LOCATIONS,
                param: {
                    search: value,
                    location: 'destination'
                }
            });
            return;
        } else if(typeof value !== 'string' && key === 'location_type') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, location: value.value, location_type_name: value.name };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(typeof value !== 'string' && key === 'weekly_off') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route,  weekly_off: value.value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(typeof value === 'string' && ['notification_emails', 'notification_numbers'].indexOf(key) === -1){
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, [key]: value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(['notification_emails', 'notification_numbers'].indexOf(key) > -1){
            let notification_emails = [];
            let notification_numbers = [];
            let data = value.split(',');
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                if(key === 'notification_emails') {
                    notification_emails = isClear ? [] : route.notification_emails;
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    return { ...route, notification_emails };
                } else if( key === 'notification_numbers') {
                    notification_numbers = isClear ? [] : route.notification_numbers;
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    return { ...route, notification_numbers };
                }
                return { ...route, [key]: value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(['source', 'destination'].indexOf(key) > -1) {
            let { endLocation, startLocation, routeDetails } = this.state;
            if(key === 'source' && value) {
                endLocation.source = false;
                startLocation.source = false
            }
            const newRouteDetails = routeDetails.map((route) => {
                if(route.id !== id) {
                    return { ...route, source: key === 'source' ? !(value) : route.source }
                }
                return { ...route,
                    source: key === 'source' ? value : value ? !value : route.source,
                    destination: key === 'destination' ? value : value ? !value : route.destination
                };
            });
            this.setState({
                startLocation,
                endLocation,
                routeDetails: newRouteDetails
            });
        } else {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, ...value };
            });
            this.setState({routeDetails: newRouteDetails}, this.getTatDetails);
        }
    }


    handleStartEndlocationChange(key, value, which, isClear) {
        if (typeof value === 'string' && key==='location') {
            if (which === "start") {
                let startLocation = this.state.startLocation;
                if (value){
                    startLocation.city = value;
                    startLocation.plant_name = value;
                } else {
                    startLocation =  {...JSON.parse(JSON.stringify(emptyLocation)), id: "start"}
                }
                this.setState({startLocation});
            }
            else if (which==='end'){
                let endLocation = this.state.endLocation;
                if (value){
                    endLocation.city = value;
                    endLocation.plant_name = value;
                } else {
                    endLocation = {...JSON.parse(JSON.stringify(emptyLocation)), id: "end"}
                }
                this.setState({endLocation});
            }
            const { dispatch } = this.props;
            dispatch({
                type: GET_LOCATIONS,
                param: {
                    search: value,
                    location: which === "start" ? 'source' : 'destination'
                }
            });
            return;
        }
        else if (typeof value === 'string' && ['notification_emails', 'notification_numbers'].indexOf(key) === -1) {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, [key]:value}});
            }
            else {
                this.setState({endLocation: {...this.state.endLocation, [key]:value}});
            }
        }  else if (typeof value !== 'string' && key === 'location_type') {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, location_type:value.value, location_type_name: value.name}});
            }
            else {
                this.setState({endLocation:  {...this.state.endLocation, location_type:value.value, location_type_name: value.name}});
            }
        } else if (typeof value !== 'string' && key === 'weekly_off') {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, weekly_off: value.name}});
            }
            else {
                this.setState({endLocation:  {...this.state.endLocation, weekly_off: value.name}});
            }
        } else if(['notification_emails', 'notification_numbers'].indexOf(key) > -1){
            let notification_emails = isClear ? [] : (which == "start" ? this.state.startLocation.notification_emails : this.state.endLocation.notification_emails);
            let notification_numbers = isClear ? [] : (which == "start" ? this.state.startLocation.notification_numbers : this.state.endLocation.notification_numbers);
            let data = value.split(',');
            if (which == "start") {
                if(key === 'notification_emails') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    this.setState({ startLocation: {...this.state.startLocation, notification_emails }});
                } else if( key === 'notification_numbers') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    this.setState({ startLocation: {...this.state.startLocation, notification_numbers }});
                }
            } else {
                if(key === 'notification_emails') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, notification_emails }});
                } else if( key === 'notification_numbers') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, notification_numbers }});
                }
            }
        }  else if(['source','destination'].indexOf(key) > -1) {
            let { endLocation, startLocation, routeDetails } = this.state;
            if (which == "start") {
                if(key === 'source' && value) {
                    endLocation.source = false;
                    routeDetails.map(route => {
                        route.source = false;
                    })
                }
                this.setState({
                    startLocation:  {...this.state.startLocation,
                                        source: key === 'source' ? value : value ? !value : this.state.startLocation.source,
                                        destination: key === 'destination' ? value :  value ? !value : this.state.startLocation.destination,
                                    },
                    endLocation,
                    routeDetails
                });
            } else {
                if(key === 'source' && value) {
                    startLocation.source = false;
                    routeDetails.map(route => {
                        route.source = false;
                    })
                }
                this.setState({
                    endLocation:  {...this.state.endLocation,
                                        source: key === 'source' ? value : value ? !value : this.state.endLocation.source,
                                        destination: key === 'destination' ? value :  value ? !value : this.state.endLocation.destination,
                                    },
                    startLocation,
                    routeDetails
                });
            }
        }
        else {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, ...value }}, () => {
                    const { dispatch } = this.props;
                    if(this.state.startLocation && TTBIL.includes(userDetails.company_id)) {
                        dispatch({
                            type: GET_DO_NO_BASED_ON_FROM_LOC,
                            params: {
                                branch_id: this.state.startLocation.branch_id,
                                start_date: this.state.startDate && this.state.startDate.valueOf()
                            },
                            onSuccess: (data) => {
                                if((data || {}).do_no) {
                                    this.setState({
                                        doNo: data.do_no
                                    })
                                }
                            }
                        })
                    }
                    if (this.state.endLocation.branch_id) {
                        const { routeDetails, startLocation, endLocation } = this.state;
                        // let destinations = ""
                        // if(routeDetails.length > 0) {
                        //     routeDetails.map(route => {
                        //         if (route.branch_id) {
                        //             destinations = `${destinations}${route.branch_id},`;
                        //         }
                        //     })
                        // }
                        // if (this.state.endLocation.branch_id) {
                        //     destinations = `${destinations}${this.state.endLocation.branch_id}`;
                        // }
                        // const data = {
                        //     source: this.state.startLocation.branch_id,
                        //     destinations,
                        //     // destinations: [this.state.endLocation.latitude, `${this.state.endLocation.longitude}| `]
                        //     // source_lng: this.state.startLocation.longitude,
                        //     // destination_lat: this.state.endLocation.latitude,
                        //     // destination_lng: this.state.endLocation.longitude
                        // }
                        // if (!isSeeker) {
                        //     data.seeker_id = this.state.company_id
                        // }
                        const data = {
                            seeker_id: this.state.company_id || userDetails.user_id,
                            route_details: []
                        }
                        if(startLocation) {
                            data.route_details.push(startLocation);
                        }
                        if(routeDetails.length > 0) {
                            routeDetails.map(route => {
                                data.route_details.push(route)
                            })
                        }
                        if(endLocation) {
                            data.route_details.push(endLocation);
                        }
                        dispatch({
                            type: GET_TAT_DETAILS,
                            data,
                            onSuccess: (data) => {
                                if(data.route_details && data.route_details.length > 0) {
                                    let { routeDetails, endLocation, startLocation } = this.state;
                                    if(data.route_details.length > 2) {
                                        routeDetails = [];
                                        data.route_details.map((route, index) => {
                                            if(index !== 0 && index !==(data.route_details.length - 1)) {
                                                routeDetails.push(route);
                                            }
                                        })
                                    }
                                    this.setState({
                                        startLocation: {
                                            ...startLocation,
                                            ...data.route_details[0]
                                        },
                                        routeDetails,
                                        endLocation: {
                                            ...endLocation,
                                            ...data.route_details[data.route_details.length - 1]
                                        },
                                        turnAroundTime: data.tat,
                                        routeCode: data.route_code
                                    })
                                }
                            }
                        });
                    }
                });
            }
            else {
                this.setState({endLocation: {...this.state.endLocation, ...value}}, () => {
                    if (this.state.startLocation.branch_id) {
                        const { dispatch } = this.props;
                        const { routeDetails, startLocation, endLocation } = this.state;
                        // let destinations = ""
                        // if(routeDetails.length > 0) {
                        //     routeDetails.map(route => {
                        //         if (route.branch_id) {
                        //             destinations = `${destinations}${route.branch_id},`;
                        //         }
                        //     })
                        // }
                        // if (this.state.endLocation.branch_id) {
                        //     destinations = `${destinations}${this.state.endLocation.branch_id}`;
                        // }
                        // const data = {
                        //     source: this.state.startLocation.branch_id,
                        //     // destinations: [this.state.endLocation.latitude, `${this.state.endLocation.longitude}| `]
                        //     destinations,
                        //     // source_lng: this.state.startLocation.longitude,
                        //     // destination_lat: this.state.endLocation.latitude,
                        //     // destination_lng: this.state.endLocation.longitude
                        // }
                        // if (!isSeeker) {
                        //     data.seeker_id = this.state.company_id
                        // }

                        const data = {
                            seeker_id: this.state.company_id || userDetails.user_id,
                            route_details: []
                        }
                        if(startLocation) {
                            data.route_details.push(startLocation);
                        }
                        if(routeDetails.length > 0) {
                            routeDetails.map(route => {
                                data.route_details.push(route)
                            })
                        }
                        if(endLocation) {
                            data.route_details.push(endLocation);
                        }
                        dispatch({
                            type: GET_TAT_DETAILS,
                            data,
                            onSuccess: (data) => {
                                if(data.route_details && data.route_details.length > 0) {
                                    let { routeDetails, endLocation, startLocation } = this.state;
                                    if(data.route_details.length > 2) {
                                        routeDetails = [];
                                        data.route_details.map((route, index) => {
                                            if(index !== 0 && index !==(data.route_details.length - 1)) {
                                                routeDetails.push(route);
                                            }
                                        })
                                    }
                                    this.setState({
                                        startLocation: {
                                            ...startLocation,
                                            ...data.route_details[0]
                                        },
                                        routeDetails,
                                        endLocation: {
                                            ...endLocation,
                                            ...data.route_details[data.route_details.length - 1]
                                        },
                                        turnAroundTime: data.tat,
                                        routeCode: data.route_code
                                    })
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    onChange = (e) => {
        let { name, value, type } = e.target;
        if (name === 'transporterContact' && value.length > 10) {
            return;
        } else if (name === 'vehicleRegNo') {
            if ((value.match(/^\w+$/) || value === '') && value.length <= 11) {
                value = value.toUpperCase();
            } else {
                return;
            }
        }

        if (type === 'text' && value) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        this.setState({
            [name]: value,
            error: false
        });
    }
    
    getVehicleDetails = (vehicle_no) => {
        const { dispatch } = this.props;
        const params = {
            trip_mode: 2,
            seeker_id: this.state.company_id,
            limit: 100,
            offset: 0,
            vehicle_no
        }
        dispatch({
            type: GET_VEHICLE_DETAILS,
            params
        })
    }
        

    selectTab = (activeTab) => {
        this.setState({
            trackingPreference: activeTab
        });
    }

    selectTabValue = (activeTab) => {
        this.setState({
            highValue: activeTab
        });
    }

    handleNotificationAccountChange(which, field, value, index) {
        if (which === "head") {
            const newAccounts = this.state.headOfficeNotification.accounts.map((account) => {
                if (account.id !== index) {
                    return account;
                }
                if (field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newHeadeNotification = { ...this.state.headOfficeNotification};
            newHeadeNotification.accounts = newAccounts;
            this.setState({headOfficeNotification: newHeadeNotification});
        } else if (which === "plant") {
            const newAccounts = this.state.plantNotification.accounts.map((account) => {
                if (account.id !== index) {
                    return account;
                }
                if (field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newPlantNotification = { ...this.state.plantNotification};
            newPlantNotification.accounts = newAccounts;
            this.setState({plantNotification: newPlantNotification});
        } else if (which == "depo") {
            const newAccounts = this.state.depoNotification.accounts.map((account) => {
                if (account.id !== index) {
                    return account;
                }
                if (field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newDepoNotification = { ...this.state.depoNotification};
            newDepoNotification.accounts = newAccounts;
            this.setState({depoNotification: newDepoNotification});
        }
    }

    validateRequiredFields = () => {
        if (!this.state.doNo) {
            scroll(this.doNo.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter the DO No.', errorField: 'doNo'});
            return;
        }else if (!this.state.lrNo) {
            scroll(this.lrNo.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter the LR No.', errorField: 'lrNo'});
            return;
        } else if (!this.state.itemName) {
            scroll(this.itemName.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter the Item name', errorField: 'itemName'});
            return;
        } else if(userDetails.company_text_id === 'TTBIL' && (!this.state.isVehicleSelected || !this.state.vehicleRegNo)){
            scroll(this.doNo.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter vehicle details', errorField: 'vehicleRegNo'});
            return;
        }else if (!this.state.startLocation.city) {
            scroll(this.RouteDetails.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter route details', errorField: 'routeDetails'});
            return;
        } else if (!this.state.transporterName) {
            scroll(this.transporterDetails.offsetTop - 150);
            this.setState({ error : true, errorMessage : 'Please enter transporter details', errorField: 'transporterDetails'});
            return;
        } else if (this.state.driverDetails.length) {
            for (var i in this.state.driverDetails) {
                if (this.state.driverDetails[i].number && this.state.driverDetails[i].number.length < 10){
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState({ error : true, errorMessage : 'Invalid Phone No.', errorField: 'driverNo'});
                    return;
                } else if (this.state.driverDetails[i].number && !Object.keys(this.state.driverDetails[i].operatorType).length && this.state.trackingPreferences[1].enabled) {
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState({ error : true, errorMessage : 'Please Select the Operator Type', errorField: 'driverNo'});
                    return;
                } else if (Object.keys(this.state.driverDetails[i].operatorType).length && this.state.trackingPreferences[1].enabled && !this.state.driverDetails[i].number) {
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState({ error : true, errorMessage : 'Please Enter the Driver No.', errorField: 'driverNo'});
                    return;
                }
            }
        } 
        
        if (!isSeeker && !this.state.company_id) {
            scroll(this.company_id.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Select the Company.', errorField: 'company_id'});
            return;
        }
        return true;
    }

    saveTrip() {
        const {searchList}=this.state;
        if (this.state.file !== null) {
            this.uploadTrips();
        } else {
            if (this.validateRequiredFields()) {
                const dataToSave = {
                    "trip_mode": 2,
                    "provider_id": isSeeker ? this.state.transporterId : getUser().company_id,
                    "do_no" : this.state.doNo,
                    "lr_no" : this.state.lrNo,
                    "invoice_no" : this.state.invoiceNo,
                    "eway_bill_no" : this.state.ewayBillNo,
                    "high_value_shipment" : this.state.highValue === 1,
                    "item_name" : this.state.itemName,
                    "service_type" : this.state.serviceType.value,
                    "bound_type" : this.state.boundType.value,
                    "run_type" : this.state.runType.value,
                    "sub_service_type" : this.state.subRequestType.value,
                    "start_date": this.state.startDate && this.state.startDate.valueOf(),
                    "di_time": (searchList.plans && searchList.plans.length === 1) ?  searchList.plans[0].di_time : this.state.di_time,
                    "customer_type": this.state.customer_type,
                    "route_code": this.state.filterScheduler.route_code,
                    "tat": this.props.tat,
                    "comments": this.state.comments,
                    // "value": {
                    //     "text": this.state.itemValue,
                    //     "type": this.state.valueUnit.value
                    // },
                    "cargo": {
                        "text": this.state.cargoWeight,
                        "type": this.state.cargoUnit.value
                    },
                    "route_details": [
                        {
                            ...this.state.startLocation,
                            'pincode' : this.state.startLocation.pincode ? this.state.startLocation.pincode.toString() : this.state.startLocation.pincode,
                            'location': this.state.startLocation.city + ', ' + this.state.startLocation.state + ', ' + this.state.startLocation.country
                        },
                        
                        {
                            ...this.state.endLocation,
                            'pincode' : this.state.endLocation.pincode ? this.state.endLocation.pincode.toString() : this.state.endLocation.pincode,
                            'location': this.state.endLocation.city + ', ' + this.state.endLocation.state + ', ' + this.state.endLocation.country
                        },
                        {
                            ...this.state.returnLocation,
                            'pincode' : this.state.returnLocation.pincode ? this.state.returnLocation.pincode.toString() : this.state.returnLocation.pincode,
                            "location": this.state.returnLocation.city + ', ' + this.state.returnLocation.state + ', ' + this.state.returnLocation.country
                        }
                    ],
                    "geofence_locations": (this.state.geofenceLocations || []).map((location)=> {
                        return {
                            location: location.location,
                            latitude: location.latitude,
                            longitude: location.longitude,
                            geofence_radius: location.geofence_radius,
                        }
                    }),
                    "transporter_details" : {
                        "name" : this.state.transporterName,
                        "email" : this.state.transporterEmail,
                        "number" : this.state.transporterContact,
                        "pan_no" : this.state.transporterPan
                    },
                    "driver_details" : this.state.driverDetails.map((driver, index) => {
                        return {
                            'name': driver.name || '',
                            first_name: driver.firstName,
                            last_name: driver.lastName,
                            'number': driver.number,
                            operator_type: driver.operatorType.id,
                            operator_name: driver.operatorType.name,
                            from: driver.fromLocation || {},
                            to: driver.toLocation || {}
                        }
                    }),
                    "vehicle_details" : {
                        "vehicle_type" : this.state.vehicleType.name ? this.state.vehicleType.name : '',
                        "body_type" : this.state.vehicleBodyType.name ? this.state.vehicleBodyType.name : '',
                        "vehicle_no" : this.state.vehicleRegNo
                    },
                    "tracking_preference" : this.state.trackingPreferences,
                    "notification_settings": [
                        this.state.headOfficeNotification,
                        this.state.plantNotification,
                        this.state.depoNotification
                    ],
                    "gps_details": this.state.trackingPreferences[2].enabled ? this.state.gpsVendors : undefined,
                };
                dataToSave.route_details = (dataToSave.route_details || []).filter(rte => rte.branch_id).map((route)=> {
                    const returnRoute = {...route};
                    delete returnRoute.plant_name;
                    returnRoute.id ?  delete returnRoute.id : null;
                    return returnRoute;
                });

                if (!isSeeker) {
                    dataToSave.seeker_id = this.state.company_id
                }
                const { dispatch } = this.props;
                dispatch({
                    type: ADD_TRIP,
                    trip: dataToSave
                });
            }
        }
    }

    getAddTripTemplate = (tripMode) => {
        const { dispatch } = this.props;
        dispatch({ type: GET_ADD_TRIP_TEMPLATE, tripMode: tripMode });
    }

    uploadTrips() {
        const { dispatch } = this.props;
        dispatch({
            type: UPLOAD_TRIPS,
            file: this.state.file,
            params: { trip_mode: 2 }
        });
        this.setState({file: null});
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
    };

    goBack = () => {
        history.back();
    }

    onFileUpload(file) {
        this.setState({
            file: file
        });
    }

    selectOperatorType = (index, operatorType) => {
        const driverDetails = [...this.state.driverDetails];
        driverDetails[index].operatorType = operatorType;
        this.setState({
            driverDetails
        })
    }

    getProviders = (search) => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_PROVIDERS,
            search
        });
    }

    getSeekers = (search) => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_SEEKERS,
            search
        });
    }

    getSchedulerRoute = (key , value, isSearch) => {
        const { dispatch } = this.props;
        let {filterScheduler} = this.state;
        filterScheduler[key] = value;
        let data = null;
        if(isSearch){
            data = {
                search_term: isSearch ? value : '',
                type: isSearch ? key : '',
                start_date: this.state.startDate.valueOf(),
            }
        }  else {
            data = {
                start_date: this.state.startDate.valueOf(),
                route_code: filterScheduler.route_code,
                source_plant_code: filterScheduler.source_plant_code,
                dest_plant_code: filterScheduler.dest_plant_code,
                di_time: filterScheduler.di_time,
                // vehicle_type: filterScheduler.vehicle_type,
                customer_type: filterScheduler.customer_type || 0,
            }
        }
        dispatch({
            type: GET_SCHEDULER_ROUTE_DETAILS,
            data,
            onSuccess: (details) => {
                if(details){
                    this.setState({searchList: details })
                    this.getFilterData(details)
                    if(details.plans && details.plans.length === 1){
                        this.getDoNo();
                    }
                } else {
                    this.setState({searchList: [] })
                }
            }
        });
    }

    clearFilter = () => {
        this.setState({
            serviceType: serviceTypes[1],
            boundType: boundTypes[1],
            subRequestType: subRequestTypes[1],
            runType: runTypes[1],
            comments: "",
            startDate: moment(new Date()),
            di_time:  moment().format('HH:mm'),
            focused: false,
            turnAroundTime: 2,
            routeDetails: [],
            startLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "start" },
            endLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "end"},
            returnLocation: {},
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsFrom: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsTo: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            transporterName: isSeeker ? "" : userDetails.name,
            transporterEmail: isSeeker ? "" : userDetails.email,
            transporterContact: isSeeker ? "" : userDetails.contact_number,
            transporterPan: isSeeker ? "" : userDetails.pan_no,
            driverName: "",
            driverNo: "",
            driverDetails: [{ ...emptyDriver, id: uuid4() }],
            gpsVendors: {},
            vehicleBodyType: {
                id: 0,
                value: 0,
                name: ""
            },
            vehicleType: {
                id: 0,
                value: 0,
                name: ""
            },
            vehicleRegNo: "",
            trackingPreference: 2,
            headOfficeNotification:
                {
                    "plant_type": 1,
                    "plant_name": "Head Office",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            plantNotification:
                {
                    "plant_type": 2,
                    "plant_name": "Plant",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            depoNotification:
                {
                    "plant_type": 3,
                    "plant_name": "Depo",
                    "accounts": [{
                        ...emptyNotification
                    }]
            },
            doNo: "",
            lrNo: "",
            invoiceNo: "",
            ewayBillNo: "",
            itemName: "",
            itemValue: "",
            highValue: 0,
            // valueUnit: {
            //     name: "— Select Units — ",
            //     value: 0
            // },
            cargoWeight: "",
            cargoUnit: [...CARGO_UNITS].filter((cargo) => { return cargo.value === 'MT' })[0],
            trackingPreferences: [
                {
                    "tracking_type": 1,
                    "tracking_name": "App Tracking",
                    "enabled": false
                },
                {
                    "tracking_type": 2,
                    "tracking_name": "SIM Tracking",
                    "enabled": (TTBIL.includes(userDetails.company_id) ? false : true)
                },
                {
                    "tracking_type": 3,
                    "tracking_name": (TTBIL.includes(userDetails.company_id) ? "GPS Tracking" : "Third party Tracking"),
                    "enabled": (TTBIL.includes(userDetails.company_id) ? true : false)
                },
                {
                    "tracking_type": 4,
                    "tracking_name": "Manual Tracking",
                    "enabled": false
                },
                {
                    "tracking_type": 5,
                    "tracking_name": "None",
                    "enabled": false
                }
            ],
            file: null,
            fromLoc: {},
            toLoc: {},
            searchList: [],
            tripData : {},
            filterScheduler: {route_code: '', source_plant_code: '', dest_plant_code: '', di_time: '',customer_type: 0}
        })
    }

    getLocationId = (city, type) => {
        const { dispatch } = this.props;
        let { startLocation, endLocation, searchList, returnLocation } = this.state;
        let param = {
            location: type
        }
        if(TTBIL.includes(userDetails.company_id)){
            param.plant_code = city;
        } else {
            param.search = city;
        }
        dispatch({
            type: GET_LOCATIONS,
            param,
            onSuccess: (data) => {
                if(type === "source"){
                    startLocation = data[0];
                    startLocation.gate_in_time = searchList.plans[0].route_details[0].gate_in_time;
                    startLocation.gate_out_time = searchList.plans[0].route_details[0].gate_out_time;
                    this.setState({startLocation},()=>{
                        this.getDoNo();
                    });
                    returnLocation = {...startLocation}
                    returnLocation.gate_in_time = searchList.plans[0].route_details[2].gate_in_time;
                    returnLocation.gate_out_time = searchList.plans[0].route_details[2].gate_out_time;
                    this.setState({returnLocation});

                    if(searchList && searchList.plans.length === 1 && !endLocation.city){
                        this.getLocationId(this.state.searchList.plans[0].dest_plant_code, 'destination');
                    }
                } else if(type === "destination"){
                    endLocation = data[0];
                    endLocation.gate_in_time = searchList.plans[0].route_details[1].gate_in_time;
                    endLocation.gate_out_time = searchList.plans[0].route_details[1].gate_out_time;
                    endLocation.reporting_time = searchList.plans[0].route_details[1].reporting_time;
                    this.setState({endLocation});
                }
                // if(startLocation.branch_id && endLocation.branch_id){
                //     this.getDoNo(); 
                // }
            }
        
        })
        
    }
    
    getDoNo = () => {
        const { dispatch } = this.props;
        if(this.state.startLocation.branch_id && TTBIL.includes(userDetails.company_id)) {
            dispatch({
                type: GET_DO_NO_BASED_ON_FROM_LOC,
                params: {
                    branch_id: this.state.startLocation.branch_id,
                    start_date: this.state.startDate && this.state.startDate.valueOf()
                },
                onSuccess: (data) => {
                    if((data || {}).do_no) {
                        this.setState({
                            doNo: data.do_no
                        })
                    }
                }
            })
        }
    }
    
    getFilterData = (searchList) => {
        let {filterScheduler, vehicleType} = this.state;
        let filterList = {routeCode: [], sourceList: [], destList: [], diTimeList: [], vehicleList: []};
        if(searchList.route_codes && searchList.route_codes.length > 0){
            {searchList.route_codes.map((data,index) => {
            let route = {value: 0, name: ''}
            route.name = data;
            route.value = index+1;
            filterList.routeCode.push(route)
            })}}
        if(searchList.src_codes && searchList.src_codes.length > 0){
            {searchList.src_codes.map((data,index) => {
            let source = {value: 0, name: ''}
            source.name = data;
            source.value = index+1;
            filterList.sourceList.push(source)
            })}}
        if(searchList.dest_codes && searchList.dest_codes.length > 0){
            {searchList.dest_codes.map((data,index) => {
            let destination = {value: 0, name: ''}
            destination.name = data;
            destination.value = index+1;
            filterList.destList.push(destination)
            })}}
        if(searchList.di_times && searchList.di_times.length > 0){
            {searchList.di_times.map((data,index) => {
            let list = {value: 0, name: ''}
            list.name = data;
            list.value = index+1;
            filterList.diTimeList.push(list)
            })}}
        // if(searchList.vehicle_types && searchList.vehicle_types.length > 0){
        //     {searchList.vehicle_types.map((data,index) => {
        //         let vehicle = {value: 0, name: ''}
        //         vehicle.name = data;
        //         vehicle.value = index+1;
        //         filterList.vehicleList.push(vehicle)
        //     })}
        //     if (searchList.vehicle_types.length == 1) {
        //         filterScheduler.vehicle_type = searchList.vehicle_types[0];
        //         vehicleType.name = filterScheduler.vehicle_type;
        //     }
        // }
        this.setState({ tripData: filterList, filterScheduler, vehicleType })
    }

    onChangeComments = (e) => {
        this.setState({ comments : e.target.value });
    }

    validateEmail = (email) => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result) {
            this.setState({
                emailError:false,
                email:email
            })
        } else {
            this.setState({
                emailError:true
            })
        }
    }

    searchLocation = (value, id, field , isSearch) => {
        const newDrivers = this.state.driverDetails.map((driver) => {
            if (driver.id !== id) {
                return driver;
            }
            const loc = {
                location : isSearch ? value : value.display_name,
                latitude : isSearch ? '' : value.latitude,
                longitude : isSearch ? '' : value.longitude
            };
            return { ...driver, [field]: loc };
        });
        this.setState({ driverDetails: newDrivers }, () => {
            if (isSearch) {
                const { dispatch } = this.props;
                dispatch({
                    type: GET_LOCATIONS,
                    param: {
                        search: value
                    }
                });
            }
        });
    }

    getTatDetails = () => {
        const { startLocation, endLocation, company_id } = this.state;
        const { dispatch } = this.props;
        const data = {
            source_lat: startLocation.latitude,
            source_lng: startLocation.longitude,
            destination_lat: endLocation.latitude,
            destination_lng: endLocation.longitude,
            seeker_id: company_id
        };
        dispatch({
            type: GET_TAT_DETAILS,
            data
        });
    }

    handleGeofenseLocationChange(value, id) {
        const newLocs = this.state.geofenceLocations.map((loc) => {
            if(loc.id !== id) {
                return loc;
            }
            return { ...loc, "location": value };
        });
        this.setState({geofenceLocations: newLocs});
    }

    fetchLocationsForGeoFencing = (value, index)=> {
        const {geofenceLocations} = this.state;
        geofenceLocations[index]['location'] = value;
        if (value.length <= 0) {
            geofenceLocations[index]['latitude'] = 0;
            geofenceLocations[index]['longitude'] = 0;
        }
        this.setState({
            geofenceLocations
        });
    }

    handleGeoFencingLocationSelect = (address, index)=> {
        const {geofenceLocations} = this.state;
        geofenceLocations[index]['location'] = address;
        this.setState({
            geofenceLocations
        })
        geocodeByAddress(address)
        .then(results => {
            this.setState({
                geoCodedAddress: results[0]
            }, () => {
                this.getLatLongForGeoFenceLocation(geofenceLocations[index], index);
            });
        });
    }

    getLatLongForGeoFenceLocation = async (data, index) => {
        await this.setGeoFenceLocationCoordinates(data, index);
    }

    async setGeoFenceLocationCoordinates(geoFenceLocation, index) {
        try {
            const { geofenceLocations } = this.state;
            const item = geofenceLocations[index];
            const location = item.location || "";

            if (location) {
                const geocodeData = await geocodeByAddress(location);
                const coordinates = await getLatLng(geocodeData[0]);

                geofenceLocations[index]['latitude'] = coordinates.lat;
                geofenceLocations[index]['longitude'] = coordinates.lng;
                geofenceLocations[index]['location'] = location;
            }

            this.setState({
                geofenceLocations
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    fetchLocations = (value, name, index) => {
        const {fromLoc, toLoc, driverDetails} = this.state;
        driverDetails[index][name]['place'] = value;
        if (value.length <= 0) {
            driverDetails[index][name]['latitude'] = 0;
            driverDetails[index][name]['longitude'] = 0;
        }
        this.setState({
            driverDetails
        });
    }

    handleLocationSelect = (address, name, index) => {
        const {fromLoc, toLoc, driverDetails} = this.state;
        driverDetails[index][name]['place'] = address;
        this.setState({
            driverDetails
        });

        geocodeByAddress(address).then(results => {
            this.setState({
                geoCodedAddress: results[0]
            }, () => {
                this.getLatLongForLocation(driverDetails[index], index);
                // this.getLatLongForLocation()
            });
        });
    }

    getLatLongForLocation = async (data, index) => {
        await this.setGeoLocationCoordinates(data, index);
    }

    async setGeoLocationCoordinates(driverData, index) {
        try {
            const { driverDetails } = this.state;
            const item = driverDetails[index];
            const fromLocation = item.fromLocation.place || "";
            const toLocation = item.toLocation.place || "";

            if (item.fromLocation && item.fromLocation.place) {
                const from = await geocodeByAddress(fromLocation);
                const fromCoord = await getLatLng(from[0]);

                driverDetails[index].fromLocation['latitude'] = fromCoord.lat;
                driverDetails[index].fromLocation['longitude'] = fromCoord.lng;
                driverDetails[index].fromLocation['place'] = fromLocation;
            }

            if (item.toLocation && item.toLocation.place) {
                const to = await geocodeByAddress(toLocation);
                const toCoord = await getLatLng(to[0]);

                driverDetails[index].toLocation['latitude'] = toCoord.lat;
                driverDetails[index].toLocation['longitude'] = toCoord.lng;
                driverDetails[index].toLocation['place'] = toLocation;
            }
            this.setState({
                // locationLatLong,
                driverDetails
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    onVehicleChange = (value) => {
        if(value.length >= 3 ){
            this.getVehicleDetails(value)
        }
        this.setState({vehicleRegNo: value, isVehicleSelected: false})
    }

    selectVehicle = (value) => {
        const { vehicleList } = this.props;
        let { vehicleRegNo, vehicleType } = this.state;
        const vehicleIndex = (vehicleList || []).findIndex(vl => vl.vehicle_reg_no == value.name);
        vehicleRegNo = vehicleList[vehicleIndex].vehicle_reg_no;
        vehicleType.name = vehicleList[vehicleIndex].vehicle_size;
        vehicleType.id = vehicleList[vehicleIndex].vehicle_size;
        vehicleType.value = vehicleList[vehicleIndex].vehicle_size;
        
        this.setState({vehicleRegNo, vehicleType, isVehicleSelected: true})
    }

    render() {
        const { locations, submitting, providers, gpsVendorType, vehicleTypes, vehicleBodyTypes, seekers, operatorTypes } = this.props;
        // let turnAroundTime = this.props.tat ? turnAroundTimes.filter((day) => { return (day.value.toLowerCase() == this.props.tat.toLowerCase())})[0] : this.state.turnAroundTime;
        const { filterScheduler, searchList, startLocation, endLocation } = this.state;
        if(searchList && searchList.plans && searchList.plans.length === 1 && !(startLocation.branch_id || endLocation.branch_id)){
            // this.getLocationId(this.state.searchList.plans[0].dest_plant_code, 'destination');
            this.getLocationId(this.state.searchList.plans[0].source_plant_code, 'source');
        }
        (providers || []).map((provider) => {
            provider.display_name = provider.name + ' - (' + provider.email + ')';
        });
        const tripMode = 2;
        return (
            <Sticky activeLink={'trips'}>
                {submitting && <ActionLoader fixed={true} />}
                <section className="trip-detail-container pr-30 pl-10 add-trip-container">
                    <section className="trip-title-container flex flex-between mb-10">
                        <div className="flex flex-middle flex-1">
                            <BackIcon onClick={this.goBack} />
                            <span className="theme-color fs-24 fBolder mr-30 ml-15">New Trip</span>
                        </div>

                        <div className="wt-200 mr-10">
                            <Button value="Get Template" onClick={() => this.getAddTripTemplate(tripMode)} />
                        </div>
                        <div className="wt-200 mr-10">
                            <UploadButton value="Upload Trips" onChange={this.onFileUpload} />
                        </div>
                        <div className="wt-200 mr-10">
                            <Button value="Save Trip" onClick={this.saveTrip} />
                        </div>
                        {TTBIL.includes(userDetails.company_id) &&
                        <div className="wt-200">
                            <Button value="Clear Filters" onClick={this.clearFilter} />
                        </div>}
                    </section>

                    <section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}>
                        <section className="pl-15 mb-30 flex mr-30">
                            <div className="wt-200">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                                    Start Date<span className="error-color fs-14">*</span>
                                </div>
                                <div className="flex date-select">
                                    <SingleDatePicker
                                        noBorder={true}
                                        displayFormat={"DD/MMM/YYYY"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        placeholder={"start date"}
                                        isDayBlocked={(obj) => { return (obj < moment(new Date())); }}
                                        date={this.state.startDate ? this.state.startDate : moment(new Date())}
                                        onDateChange={date => {
                                            this.setState({
                                                startDate: moment(new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(this.state.startDate).format('HH:mm')).getTime())
                                            })
                                        }}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        id="startDate"
                                    />
                                    <TimePicker
                                        showSecond={false}
                                        value={this.state.startDate}
                                        className="wt-100"
                                        onChange={(time) => {
                                            const { startDate } = this.state;
                                            this.setState({
                                                startDate: time ? moment(new Date(moment(startDate).format('YYYY-MM-DD') + ' ' + (time || new Date()).format('HH:mm')).getTime()) : this.state.startDate
                                            });
                                        }}
                                        format={'h:mm a'}
                                        use12Hours
                                        inputReadOnly
                                    />
                                    </div>
                            </div>
                            {TTBIL.includes(userDetails.company_id) &&
                            <Fragment>
                             
                            <div className="flex pl-10 pr-10 route-form-row-container">
                            <SecondaryRouteDetailForm
                                locations = {locations}
                                searchList={this.state.searchList}
                                typesOfLocation={typesOfLocation}
                                index={"start"}
                                route={this.state.startLocation}
                                getSchedulerRoute={this.getSchedulerRoute}
                                filterScheduler={this.state.filterScheduler}
                                tripData={this.state.tripData}
                                handleRouteDataChange={this.handleStartEndlocationChange}
                                label={"Start Location"} />
                            </div>
                            <div className="wt-180">
                                    <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                                        DI Time<span className="error-color fs-14">*</span>
                                    </div>
                                    <div className="flex">
                                        <Dropdown
                                            onSelect={(value) => {
                                                let {filterScheduler, di_time, customer_type } = this.state;
                                                di_time = value.name;
                                                customer_type = (searchList.plans && searchList.plans.length && searchList.plans[0].customer_type) || 0;
                                                filterScheduler.di_time = di_time;
                                                filterScheduler.customer_type = customer_type;
                                                this.setState({filterScheduler, di_time, customer_type}, this.getSchedulerRoute('di_time', di_time))}
                                            }
                                            items={this.state.tripData.diTimeList || []}
                                            value={(searchList.plans && searchList.plans.length === 1) ? {name: searchList.plans[0].di_time, value: searchList.plans[0].di_time} : {name: filterScheduler.di_time, value: filterScheduler.di_time}}
                                            label={'Select DI Time'}
                                            selectedClassName={'col-1'}
                                            hasClearFilter={() => {
                                                let {filterScheduler, di_time} = this.state;
                                                di_time = '';
                                                filterScheduler.di_time = di_time;
                                                this.setState({filterScheduler, di_time},this.getSchedulerRoute("di_time", di_time))}
                                            }
                                        />
                                        </div>
                                </div>
                                {!userDetails.company_text_id === 'TTBIL' &&
                                <div className="wt-200">
                                    <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                                        Vehicle Type<span className="error-color fs-14">*</span>
                                    </div>
                                    <div className="flex">
                                        <Dropdown
                                            onSelect={(value) => {
                                                let {filterScheduler, vehicleType} = this.state;
                                                filterScheduler.vehicle_type = value.name;
                                                vehicleType.name = value.name;
                                                this.setState({filterScheduler, vehicleType}, this.getSchedulerRoute('vehicle_type', value.name))}
                                            }
                                            items={this.state.tripData.vehicleList || []}
                                            value={(searchList.plans && searchList.plans.length === 1) ? {name: searchList.plans[0].vehicle_type[0].vehicle_name, value: searchList.plans[0].vehicle_type[0].vehicle_type_id} : (searchList.vehicle_types && searchList.vehicle_types.length === 1 ? {name: searchList.vehicle_types[0], value: searchList.vehicle_types[0]} : {name: filterScheduler.vehicle_type, value: filterScheduler.vehicle_type})}
                                            label={'Select Vehicle Type'}
                                            selectedClassName={'col-1'}
                                            hasClearFilter={() => {
                                                let {filterScheduler, vehicleType} = this.state;
                                                filterScheduler.vehicle_type = '';
                                                vehicleType.name = '';
                                                this.setState({filterScheduler, vehicleType}, this.getSchedulerRoute("vehicle_type", ''))}
                                            }
                                        />
                                    </div>
                                </div>}
                                
                                <div className="wt-180">
                                    <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                                        Customer Type<span className="error-color fs-14">*</span>
                                    </div>
                                    <div className="flex">
                                        <Dropdown
                                            onSelect={(value) => {
                                                let {filterScheduler, customer_type } = this.state;
                                                customer_type = value.value
                                                filterScheduler.customer_type = customer_type;
                                                this.setState({filterScheduler, customer_type}, this.getSchedulerRoute('customer_type', value.value))}
                                            }
                                            items={CUSTOMER_TYPE || []}
                                            value={(searchList.plans && searchList.plans.length === 1) ? {name: (CUSTOMER_TYPE_MAP[searchList.plans[0].customer_type] || ''), value: searchList.plans[0].customer_type} : {name: (CUSTOMER_TYPE_MAP[filterScheduler.customer_type] || ''), value: filterScheduler.customer_type}}
                                            label={'Select Customer Type'}
                                            selectedClassName={'col-1'}
                                            hasClearFilter={() => {
                                                let {filterScheduler, customer_type} = this.state;
                                                customer_type = 0;
                                                filterScheduler.customer_type = customer_type;
                                                this.setState({filterScheduler, customer_type},this.getSchedulerRoute("customer_type", customer_type))}
                                            }
                                        />
                                    </div>
                                </div>
                                
                                </Fragment>
                                }
                                </section>
                                <section className="pl-15 mb-30 flex mr-30">
                                
                                {TTBIL.includes(userDetails.company_id) &&
                                    <div className="wt-250 mt-4" ref={(e) => {this.vehicleRegNo = e; }}>
                                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                                            Vehicle Reg No.<span className="error-color fs-14">*</span>
                                        </div>
                                        <SearchDropdown
                                            hideSearch={true}
                                            onChange={(value) => {this.onVehicleChange(value)} }
                                            onSelect={(value) => {this.selectVehicle(value)} }
                                            label={'VEHICLE REG NO.'}
                                            items={(this.props.vehicleList || []).map(vehicle => { return {name: vehicle.vehicle_reg_no, value: vehicle.vehicle_reg_no }})}
                                            value={this.state.vehicleRegNo}
                                            selectedClassName={"route-detail-search"}
                                            containerClassName={' wt-200'}
                                            hasClearFilter={() => {this.setState({vehicleRegNo: '',vehicleType: {name: '', id: 0, value: 0}, isVehicleSelected: false})}}
                                        />
                                    </div>
                                }
                                {TTBIL.includes(userDetails.company_id) &&
                                    <div className="wt-250 mt-10">
                                        <Input
                                            containerClassName="mb-10 mr-20"
                                            name="vehicleType"
                                            type="text"
                                            value={(this.state.vehicleType || {}).name}
                                            placeholder="VEHICLE TYPE"
                                            disabled={true}
                                            required={true}
                                            ref={(e) => {this.vehicleType = e; }}
                                        />
                                    </div>
                                }
                                {TTBIL.includes(userDetails.company_id) && searchList.plans && searchList.plans.length === 1 &&
                                <table className="wt-700 border-gray-1">
                                    <thead className="white-color bg-theme-color tCenter border-gray-1">
                                        <tr>
                                            <th className="col-5 border-gray-1 tCenter">Origin Gate Out</th>
                                            <th className="col-5 border-gray-1 tCenter">Destination Report Time</th>
                                            <th className="col-5 border-gray-1 tCenter">Destination Gate In</th>
                                            <th className="col-5 border-gray-1 tCenter">Destination Gate Out</th>
                                            <th className="col-5 border-gray-1 tCenter">Origin Gate In</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tCenter border-gray-1">
                                        <tr>
                                            <td className="border-gray-1">{moment(searchList.plans[0].route_details[0].gate_out_time).format('HH:mm')}</td>
                                            <td className="border-gray-1">{moment(searchList.plans[0].route_details[1].reporting_time).format('HH:mm')}</td>
                                            <td className="border-gray-1">{moment(searchList.plans[0].route_details[1].gate_in_time).format('HH:mm')}</td>
                                            <td className="border-gray-1">{moment(searchList.plans[0].route_details[1].gate_out_time).format('HH:mm')}</td>
                                            <td className="border-gray-1">{searchList.plans[0].origin_gate_in_time}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }      
                        </section>
                        {this.state.errorField && <div className='error-color'>{this.state.errorMessage}</div>}
                    </section>

                    <section className="pl-15 mb-20 mt-40 flex ">
                        <div className="fs-18 theme-color fBolder">
                            Shipment Details
                        </div>
                    </section>

                    <section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}>
                        <section className="pl-15 mb-40 flex flex-between">
                            <Input
                                required
                                containerClassName="mb-10"
                                name="doNo"
                                type="text"
                                value={this.state.doNo}
                                placeholder="DO No."
                                onChange={this.onChange}
                                ref={(e) => { this.doNo = e; }}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                errorField={this.state.errorField}
                                disabled={TTBIL.includes(userDetails.company_id)}
                            />
                            <Input
                                required
                                containerClassName="mb-10"
                                name="lrNo"
                                type="text"
                                value={this.state.lrNo}
                                placeholder="LR No."
                                onChange={this.onChange}
                                ref={(e) => { this.lrNo = e; }}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                errorField={this.state.errorField}
                            />
                            <Input
                                containerClassName="mb-10"
                                name="invoiceNo"
                                type="text"
                                value={this.state.invoiceNo}
                                placeholder="Invoice No."
                                onChange={this.onChange}
                            />
                            {TTBIL.includes(userDetails.company_id) &&
                            <Input
                                containerClassName="mb-10"
                                name="ewayBillNo"
                                type="text"
                                value={this.state.ewayBillNo}
                                placeholder="Eway Bill No."
                                onChange={this.onChange}
                            />}
                            <Input
                                required
                                containerClassName="mb-10"
                                name="itemName"
                                type="text"
                                value={this.state.itemName}
                                placeholder="Item Name"
                                onChange={this.onChange}
                                ref={(e) => { this.itemName = e; }}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                errorField={this.state.errorField}
                            />
                        </section>

                        <section className="pl-15 mb-30 flex flex-middle">
                            {/*
                                <div>
                                    <div className="mr-10 fs-14 high-value-shipment fBold">Priority Shipment?</div>
                                    <div className="tab-on-map">
                                        <Tabs selectTab={this.selectTabValue} activeTabId={this.state.highValue} tabs={shipmentValue}/>
                                    </div>
                                </div>

                                <Input
                                    required
                                    containerClassName="ml-20"
                                    name="itemValue"
                                    type="text"
                                    value={this.state.itemValue}
                                    placeholder="Value"
                                    onChange={this.onChange}
                                />
                                <div className="wt-250">
                                    <Dropdown
                                        onSelect={(value) => {this.setState({valueUnit: value})}}
                                        customClass="ml-20"
                                        items={valueUnits}
                                        value={this.state.valueUnit}
                                        label={''}
                                    />
                                </div>
                            */}

                            <Input name="cargoWeight" type="number" value={this.state.cargoWeight} placeholder="Cargo Weight" onChange={this.onChange}/>
                            <div className="wt-250 mt-25">
                                <Dropdown
                                    onSelect={(value) => {this.setState({cargoUnit: value})}}
                                    items={[...CARGO_UNITS]}
                                    value={this.state.cargoUnit}
                                    label={'Select Cargo Unit'}
                                    selectedClassName={'col-1'}
                                />
                            </div>
                        </section>
                    </section>

                    {
                        !isSeeker && <Fragment>
                            <section className={"pl-15 mb-20 flex mt-30"}>
                                <div className="fs-18 theme-color fBolder" ref={(e) => {this.company_id = e; }}>
                                    Seeker Details<span className="error-color">*</span>
                                    {
                                        this.state.error && this.state.errorMessage && this.state.errorField == 'company_id' &&
                                        <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>
                                    }
                                </div>
                            </section>

                            <section className={"pl-15 mb-30 flex flex-between box-shadow pl-20 pr-20  pb-20 br-5"}>
                                <div className="mt-20">
                                    <SearchDropdown
                                        onChange={(value) => {
                                            this.getSeekers(value);
                                            if (value) {
                                                this.setState({
                                                    company_id: value,
                                                    company: value
                                                })
                                            } else {
                                                this.setState({
                                                    company_id: '',
                                                    company: ''
                                                })
                                            }
                                        }}
                                        onSelect = {(seeker) => {
                                            this.setState({
                                                company_id: seeker.company_id,
                                                company: seeker.name
                                            }, () => {
                                                const { startLocation , endLocation, company_id } = this.state;
                                                if (startLocation.latitude &&  startLocation.longitude && endLocation.latitude && endLocation.longitude) {
                                                    this.getTatDetails();
                                                }
                                            })
                                        }}
                                        label={'Search Company'}
                                        items={seekers || []}
                                        // display_name={'emailName'}
                                        value={this.state.company}
                                        hasClearFilter = {() => {
                                            this.setState({
                                                company_id: '',
                                                company: ''
                                            })
                                        }}
                                        containerClassName={' wt-300'}
                                    />
                                </div>
                            </section>
                        </Fragment>
                    }

                    {/* <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder" ref={(e) => { this.RouteDetails = e; }}>
                            Route details<span className="error-color">*</span>
                            {
                                this.state.error && this.state.errorMessage && this.state.errorField == 'routeDetails' &&
                                <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>
                            }
                        </div>
                    </section> */}
                    {!TTBIL.includes(userDetails.company_id) &&
                    <Fragment>
                    <section className="pl-15 mb-20 flex mt-40">
                            <div className="fs-18 theme-color fBolder" ref={(e) => { this.RouteDetails = e; }}>
                                Route details<span className="error-color">*</span>
                                {this.state.error && this.state.errorMessage && this.state.errorField == 'routeDetails' &&
                                <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>}
                                
                            </div>
                    </section>
                    {/* <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        <div className="flex pl-20 pr-30 route-form-row-container">
                            <span className="location-side-marker">
                                <span className="blue"> </span>
                            </span>
                            <RouteDetailForm
                                locations={locations}
                                route={this.state.startLocation}
                                typesOfLocation={typesOfLocation}
                                index={"start"}
                                handleRouteDataChange={this.handleStartEndlocationChange}
                                label={"Start Location"}
                            />
                        </div>

                        {
                            this.state.routeDetails.map((route, index) => {
                                return (
                                    <div key={index + '-div'} className="flex mt-10 pl-20 pr-30 route-form-row-container">
                                        <span className="location-side-marker">
                                            <span className="blue hole"> </span>
                                        </span>
                                        <span className="location-side-marker right">
                                        </span>
                                        <span className="remove-button">
                                            <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeLocation(route.id) }}> - </span>
                                        </span>
                                        <RouteDetailForm
                                            locations={locations}
                                            key={route.id}
                                            route={route}
                                            typesOfLocation={typesOfLocation}
                                            index={route.id}
                                            handleRouteDataChange={this.handleRouteDataChange}
                                            label={"Enter Location"}
                                        />
                                    </div>
                                );
                            })
                        }

                        <div className="flex flex-right add-trip-add-btn-row">
                            <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addLocation(uuid4()) }}> + </span>
                            <span className="fs-24 add-trip-add-row-btn left"></span>
                        </div>
 */}
                        {/*
                            <div className="flex pl-20 pr-30 end-row route-form-row-container">
                                <span className="location-side-marker">
                                    <span className="blue"> </span>
                                </span>
                                <RouteDetailForm
                                    locations={locations}
                                    route={this.state.endLocation}
                                    typesOfLocation={typesOfLocation}
                                    index={"end"}
                                    handleRouteDataChange={this.handleStartEndlocationChange}
                                    label={"End Location"}
                                />
                            </div>
                        */}
                    {/* </section> */}
                    
                    <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                            <div className="flex pl-20 pr-30 fBolder">
                                    <div className="wt-65 mt--5">
                                        <React.Fragment>
                                            <a data-tip data-for={`src`} className={'ml-50'}>
                                                <div className="pb-5 fBold mt--15">Origin
                                                    <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                                </div>
                                            </a>
                                            <ReactTooltip id={`src`} type="info" effect="solid" border={true}>
                                                Only the INITIAL shipment LOADING POINT must be considered as origin.
                                            </ReactTooltip>
                                        </React.Fragment>
                                    </div>
                                    <div className="wt-65 mt--5">
                                        <React.Fragment>
                                            <a data-tip data-for={`destn`} className={'ml-50'}>
                                                <div className="pb-5 fBold mt--15">Destn.
                                                    <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                                </div>
                                            </a>
                                            <ReactTooltip id={`destn`} type="info" effect="solid" border={true}>
                                                Mark shipment UNLOADING POINT(S) as destination(in case of multiple drops each drop point needs to be marked as destination).
                                            </ReactTooltip>
                                        </React.Fragment>
                                    </div>
                                    <div className="wt-200 ml-10">
                                        Location
                                    </div>
                                    <div className="wt-150 ml-10">
                                        Location Type
                                    </div>
                                    <div className="wt-80 ml-10">
                                        PIN Code
                                    </div>
                                    <div className="wt-200 ml-10">
                                        Notification Emails
                                    </div>
                                    <div className="wt-175 ml-10">
                                        Notification Numbers
                                    </div>
                                    <div className="wt-80 pl-10 ml-10">
                                        TAT
                                    </div>
                            </div>
                            
                            <div className="flex pl-20 pr-30 route-form-row-container">
                                <span className="location-side-marker">
                                    <span className="blue"> </span>
                                </span>
                                <RouteDetailForm
                                    locations = {locations}
                                    route={this.state.startLocation}
                                    typesOfLocation={typesOfLocation}
                                    index={"start"}
                                    handleRouteDataChange={this.handleStartEndlocationChange}
                                    label={"Start Location"} />
                            </div>
                            
                            {
                                this.state.routeDetails.map((route, index) => {
                                    return <div key={index + '-div'} className="flex mt-10 pl-20 pr-30 route-form-row-container">
                                            <span className="location-side-marker">
                                                <span className="blue hole"> </span>
                                            </span>
                                            <span className="location-side-marker right">
                                            </span>
                                            <span className="remove-button">
                                                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeLocation(route.id) }}> - </span>
                                            </span>
                                            <RouteDetailForm
                                                locations = {locations}
                                                key={route.id}
                                                route={route}
                                                typesOfLocation={typesOfLocation}
                                                index={route.id}
                                                handleRouteDataChange={this.handleRouteDataChange}
                                                label={"Enter Location"}/>
                                            </div>
                                })
                            }
                            <div className="flex flex-right add-trip-add-btn-row">
                                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addLocation(uuid4()) }}> + </span>
                                <span className="fs-24 add-trip-add-row-btn left"></span>
                            </div>
                            <div className="flex pl-20 pr-30 end-row route-form-row-container">
                                <span className="location-side-marker">
                                    <span className="blue"> </span>
                                </span>
                                <RouteDetailForm
                                    locations = {locations}
                                    route={this.state.endLocation}
                                    typesOfLocation={typesOfLocation}
                                    index={"end"}
                                    handleRouteDataChange={this.handleStartEndlocationChange}
                                    label={"End Location"} />
                            </div>
                            <div className="wt-200 mt-10 ml-20 tat-disp inBlock">
                                <Input required containerClassName="mb-10 " name="turnAroundTime" type="number" value={this.state.turnAroundTime} placeholder="TAT (TURN AROUND TIME) Days" onChange={this.onChange} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            </div>
                            <div className="wt-200 mt-10 ml-50 tat-disp inBlock">
                                <Input containerClassName="mb-10 " name="routeCode" value={this.state.routeCode} placeholder="ROUTE CODE" onChange={this.onChange} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            </div>
                    </section>
                    </Fragment>}
                    <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder" ref={(e) => { this.comments = e; }}>
                            Comments/Additional Information
                            {/*
                                <span className="error-color">*</span>
                                {
                                    this.state.error && this.state.errorMessage && this.state.errorField == 'comments' &&
                                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>
                                }
                            */}
                        </div>
                    </section>

                    <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        <div>
                            <textarea className={'col-1 br-5 pb-5 pt-5 fs-14'} value={this.state.comments} onChange={this.onChangeComments}/>
                        </div>
                    </section>
                    {!TTBIL.includes(userDetails.company_id) &&
                    <Fragment>
                    <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder">
                            Geofence Locations
                        </div>
                    </section>

                    <section className="mb-30 geofence box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        {
                            this.state.geofenceLocations.map((loc, index) => {
                                return (
                                    <div className="flex mb-5 pl-20 pr" key={index}>
                                        <span className={classNames("location-side-marker", {
                                            'last-in-geofence': index === this.state.geofenceLocations.length - 1
                                        })}>
                                            <span className="blue"> </span>
                                        </span>
                                        <span className={classNames("remove-btn-row", {
                                            'first-in-geofence': index === 0
                                        })}>
                                            <span className="location-side-marker right ">
                                            </span>
                                            <span className="remove-button">
                                                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeGeoFenceLocation(loc.id) }}> - </span>
                                            </span>
                                        </span>

                                        <PlacesAutocomplete
                                            key={index}
                                            value={loc.location}
                                            onChange={(value) => {this.fetchLocationsForGeoFencing(value, index)}}
                                            onSelect={(value) => { this.handleGeoFencingLocationSelect(value, index)}}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="dropdown-container">
                                                    <div className="dropdown-container 2 ht-40 pr">
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Add location',
                                                                className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-400',
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="row-100">
                                                        <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                            {
                                                                suggestions.map((item, i) => {
                                                                    const className = "dropdown-item pl-16 ht-40";
                                                                    return (
                                                                        <li
                                                                            {...getSuggestionItemProps(item, {
                                                                                className,
                                                                            })}
                                                                        >
                                                                            <abbr title={item.description}>
                                                                                {item.description.substr(0, 75)}
                                                                                {item.description.length > 75 ? '...' : ''}
                                                                            </abbr>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                );
                            })
                        }

                        <div className="flex flex-right add-trip-add-btn-row wt-450">
                            <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addGeofenceLocation(this.state.geofenceLocations.length) }}> + </span>
                            <span className="fs-24 add-trip-add-row-btn left"></span>
                        </div>
                    </section>
                    </Fragment>}
                    <section className={"pl-15 mb-20 flex"}>
                        <div className="fs-18 theme-color fBolder mt-30" ref={(e) => { this.transporterDetails = e; }}>
                            Transporter Details<span className="error-color">*</span>
                            {
                                this.state.error && this.state.errorMessage && this.state.errorField == 'transporterDetails' &&
                                <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>
                            }
                        </div>
                    </section>

                    <section className={classNames("pl-15 mb-30 flex flex-between box-shadow pl-20 pr-20  pb-20 br-5", {'disabled' : !isSeeker})}>
                        {/*
                            <Input
                                required
                                containerClassName="mb-10"
                                name="transporterName"
                                type="text"
                                value={this.state.transporterName}
                                placeholder="NAME"
                                onChange={this.onChange}
                            />
                        */}

                        <div className="mt-20">
                            <SearchDropdown
                                onChange={(value) => {
                                    this.getProviders(value);
                                    if (value) {
                                        this.setState({
                                            transporterName: value
                                        })
                                    } else {
                                        this.setState({
                                            transporterName: '',
                                            transporterEmail: '',
                                            transporterPan: '',
                                            transporterId: '',
                                            transporterContact: ''
                                        })
                                    }
                                }}
                                onSelect={(provider)=> {
                                    this.setState({
                                        transporterName: provider.name,
                                        transporterEmail: provider.email,
                                        transporterPan: provider.pan_no,
                                        transporterId: provider.provider_id,
                                        transporterContact: provider.number,
                                        error: false
                                    })
                                }}
                                label={'Search Provider'}
                                items={providers || []}
                                value={this.state.transporterName}
                                hasClearFilter={() => {
                                    this.setState({
                                        transporterName: '',
                                        transporterEmail: '',
                                        transporterPan: '',
                                        transporterId: '',
                                        transporterContact: ''
                                    })
                                }}
                            />
                        </div>

                        <Input containerClassName="mb-10" name="transporterEmail" type="text" value={this.state.transporterEmail} placeholder="EMAIL ID" onChange={this.onChange} />
                        <Input containerClassName="mb-10" name="transporterContact" type="number" value={this.state.transporterContact} placeholder="CONTACT NO." onChange={this.onChange} />
                        <Input containerClassName="mb-10" name="transporterPan" type="text" value={this.state.transporterPan} placeholder="PAN NO." onChange={this.onChange} />
                    </section>

                    <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder">
                            Tracking preference
                        </div>
                    </section>

                    <section className="pl-15 mb-30 flex flex-between add-trip-tracking-preference box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        {
                            this.state.trackingPreferences.map((preference, index)=> {
                                
                                return (
                                    <Checkbox onCheck={this.changePreference.bind(this, index)} id={index} key={index} className="mr-70" checked={preference.enabled} label={preference.tracking_name}/>
                                );
                                
                            })
                        }
                    </section>

                    <section>
                        {
                            this.state.trackingPreferences[2].enabled &&
                            <div className="mb-20 wt-300">
                                <header className="fs-12 theme-color fBolder mb-20">GPS Vendor: </header>
                                <Dropdown
                                    value={this.state.gpsVendors || gpsVendorType[0]}
                                    onSelect={this.selectGPSVendor.bind(this)}
                                    onChange={this.selectGPSVendor.bind(this)}
                                    items={gpsVendorType}
                                    label="Select Vendor type"
                                    selectedClassName={'col-1 disabled'}
                                />
                            </div>
                        }
                    </section>

                    <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder" ref={(e) => {this.driverNo = e; }}>
                            Driver Details
                            {
                                this.state.error && this.state.errorMessage && this.state.errorField == 'driverNo' &&
                                <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>
                            }
                        </div>
                    </section>

                    <section className="mb-30 driver-container box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        {
                            (this.state.driverDetails || []).map((driver, index) => {
                                return (
                                    <div className="flex flex-row">
                                        <Input
                                            containerClassName="mb-10"
                                            name="driverName"
                                            type="text"
                                            value={driver.name}
                                            placeholder="FIRST NAME"
                                            onChange={(e) => this.handleDriverChange("firstName", e.target.value, driver.id)}
                                        />
                                        <Input
                                            containerClassName="mb-10 ml-20"
                                            name="driverName"
                                            type="text"
                                            value={driver.name}
                                            placeholder="LAST NAME"
                                            onChange={(e) => this.handleDriverChange("lastName", e.target.value, driver.id)}
                                        />

                                        <div>
                                            <Input
                                                containerClassName="mb-10 ml-20"
                                                name="driverNo"
                                                type="number"
                                                pattern="[1-9]{1}[0-9]{9}"
                                                value={driver.number}
                                                placeholder="CONTACT NO."
                                                onChange={(e) => this.handleDriverChange("number", e.target.value, driver.id)}
                                            />
                                            {
                                                driver.number.length > 1 && driver.number.length < 10 &&
                                                <span className={'error-color fs-12 ml-50'}>{`Invalid Phone No.`}</span>
                                            }
                                        </div>
                                        
                                        {/*
                                            <SearchDropdown
                                                hideSearch={true}
                                                onChange={(value) => this.searchLocation(value, driver.id, 'fromLocation', true)}
                                                onSelect={(value) => this.searchLocation(value, driver.id, 'fromLocation', false)}
                                                label={'From Location'}
                                                items={locations || []}
                                                value={driver.fromLocation.location}
                                                selectedClassName={"route-detail-search"}
                                                containerClassName={' mt-20 ml-10'}
                                                hasClearFilter={(value) => this.searchLocation('', driver.id, 'fromLocation', true)}
                                            />
                                        */}
                                        {!TTBIL.includes(userDetails.company_id) &&
                                        <Fragment>
                                        <div className="mb-5 pl-20 pr">
                                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">From Location:</div>
                                            <PlacesAutocomplete
                                                key={index}
                                                onChange={(value) => { this.fetchLocations(value, 'fromLocation', index)}}
                                                onSelect={(value) => { this.handleLocationSelect(value, 'fromLocation', index)}}
                                                searchOptions={{type:['cities']}}
                                                value={driver.fromLocation && driver.fromLocation.place ? driver.fromLocation.place : ''}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="dropdown-container">
                                                        <div className="dropdown-container 2 ht-40 pr">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'From Location',
                                                                    className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="row-100">
                                                            <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                                {
                                                                    suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75 ? '...' : ''}
                                                                                </abbr>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>

                                        {/*
                                            <SearchDropdown
                                                hideSearch={true}
                                                onChange={(value) => this.searchLocation(value, driver.id, 'toLocation', true)}
                                                onSelect={(value) => this.searchLocation(value, driver.id, 'toLocation', false)}
                                                label={'To Location'}
                                                items={locations || []}
                                                value={driver.toLocation.location}
                                                selectedClassName={"route-detail-search"}
                                                containerClassName={' mt-20 ml-10'}
                                                hasClearFilter={() => this.searchLocation('', driver.id, 'toLocation', true)}
                                            />
                                        */}

                                        <div className="mb-5 pl-20 pr">
                                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">To Location:</div>
                                            <PlacesAutocomplete
                                                key={index}
                                                onChange={(value) => { this.fetchLocations(value, 'toLocation', index)}}
                                                onSelect={(value) => { this.handleLocationSelect(value, 'toLocation', index)}}
                                                searchOptions={{type:['cities']}}
                                                value={driver.toLocation && driver.toLocation.place ? driver.toLocation.place : ''}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="dropdown-container">
                                                        <div className="dropdown-container 2 ht-40 pr">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'To Location',
                                                                    className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="row-100">
                                                             <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                                {
                                                                    suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75 ? '...' : ''}
                                                                                </abbr>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>

                                        {
                                            index > 0 &&
                                            <div className={classNames("flex flex-right add-trip-add-btn-row driver-remove wt-450 r-40", {
                                                'opr-gps': this.state.trackingPreferences[1].enabled && this.state.trackingPreferences[2].enabled
                                            })}>
                                                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeDriver(driver.id) }}> - </span>
                                            </div>
                                        }

                                        <div className="mb-20 wt-300 ml-10">
                                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">Operator Type:
                                                {driver.number.length > 0 && <span className={'error-color'}>*</span>}
                                            </div>
                                            <Dropdown
                                                value={driver.operatorType}
                                                onSelect={this.selectOperatorType.bind(this, index)}
                                                items={operatorTypes}
                                                label="Select Operator type"
                                                selectedClassName={'col-1'}
                                            />
                                        </div>
                                        </Fragment>}
                                        {/*
                                            this.state.trackingPreferences[2].enabled &&
                                            <div className="mb-20 wt-300 ml-20">
                                                <div className="fs-12 communication-color fBolder text-uppercase mb-5">GPS Vendor: </div>
                                                <Dropdown
                                                    value={driver.gpsVendor}
                                                    onSelect={this.selectGPSVendor.bind(this, index)}
                                                    items={gpsVendorType}
                                                    label="Select Vendor type"
                                                    selectedClassName={'col-1'}
                                                />
                                            </div>
                                        */}
                                    </div>
                                );
                            })
                        }
                        {!TTBIL.includes(userDetails.company_id) &&
                        <div className={classNames('flex flex-right add-trip-add-btn-row driver-add wt-450 r-40',{
                            'opr-gps': this.state.trackingPreferences[1].enabled && this.state.trackingPreferences[2].enabled
                        })}>
                            <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addDriver() }}> + </span>
                        </div>}
                    </section>
                    {!TTBIL.includes(userDetails.company_id) &&
                    <Fragment>
                    <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder">
                            Vehicle Details
                        </div>
                    </section>

                    <section className="pl-15 mb-30 flex flex-between box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                        <Dropdown
                            onSelect={(value) => {this.setState({vehicleType: value})}}
                            customClass="mr-20"
                            items={vehicleTypes}
                            value={this.state.vehicleType}
                            label={'— Select vehicle type — '}
                            selectedClassName={'col-1'}
                            searchEnabled={true}
                        />
                        <Dropdown
                            onSelect={(value) => {this.setState({vehicleBodyType: value})}}
                            customClass="mr-20"
                            items={vehicleBodyTypes}
                            value={this.state.vehicleBodyType}
                            label={'— Select body type — '}
                            selectedClassName={'col-1'}
                        />
                        <div className="wt-300" ref={(e) => {this.vehicleRegNo = e; }}>
                            <Input
                                containerClassName="mb-10"
                                name="vehicleRegNo"
                                type="text"
                                value={this.state.vehicleRegNo}
                                placeholder="VEHICLE REG NO."
                                onChange={this.onChange}
                            />

                            {/*
                                this.state.vehicleRegNo.length > 1 && this.state.vehicleRegNo.length < 10 &&
                                <span className={'error-color fs-12 ml-50'}>{`Vehicle Reg No. length should be 10`}</span>
                            */}
                        </div>
                    </section>

                    <section className="pl-15 flex mt-40">
                        <div className="fs-18 theme-color fBolder">
                            Notification Setting
                        </div>
                    </section>

                    <section className="pl-15 pr-15 mb-10 flex flex-between mt-20 box-shadow pl-10 pr-10 pt-20 pb-20 br-5">
                        <div className="">
                            <div className="fs-16 mb-20 fBolder communication-address-type">Head office</div>
                            <div>
                                {
                                    this.state.headOfficeNotification.accounts.map((account, index) => {
                                        return (
                                            <div className="communication-address mt-10 pr"  key={index}>
                                                {
                                                    this.state.headOfficeNotification.accounts.length > 1 &&
                                                    <span
                                                        className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                                        onClick={() => this.removeHeadOfficeAccount(index)}
                                                    >
                                                        <CloseIcon />
                                                    </span>
                                                }
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="email"
                                                    type="text"
                                                    value={account.email}
                                                    placeholder="@ EMAIL ID"
                                                    onChange={(e) => { this.handleNotificationAccountChange("head", "email", e.target.value, index)}}
                                                />
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="contactNo"
                                                    type="number"
                                                    value={account.sms}
                                                    placeholder="&#9993; SMS"
                                                    onChange={(e) => { this.handleNotificationAccountChange("head", "sms", e.target.value, index) }}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <Button value="+ Add new communication account" className="add-communication-button mt-30" onClick={this.addHeadOfficeAccount} />
                        </div>

                        <div className="">
                            <div className="fs-16 mb-20 fBolder communication-address-type">Plant</div>
                            <div >
                                {
                                    this.state.plantNotification.accounts.map((account, index) => {
                                        return (
                                            <div className="communication-address mt-10 pr"  key={index}>
                                                {
                                                    this.state.plantNotification.accounts.length > 1 &&
                                                    <span
                                                        className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                                        onClick={() => this.removePlantAccount(index)}
                                                    >
                                                        <CloseIcon />
                                                    </span>
                                                }
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="email"
                                                    type="text"
                                                    value={account.email}
                                                    placeholder="@ EMAIL ID"
                                                    onChange={(e) => { this.handleNotificationAccountChange("plant", "email", e.target.value, index)}}
                                                />
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="contactNo"
                                                    type="number"
                                                    value={account.sms}
                                                    placeholder="&#9993; SMS"
                                                    onChange={(e) => { this.handleNotificationAccountChange("plant", "sms", e.target.value, index) }}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <Button value="+ Add new communication account" onClick={this.addPlantAccount} className="add-communication-button mt-30" />
                        </div>

                        <div className="">
                            <div className="fs-16 mb-20 fBolder communication-address-type">Depot</div>
                            <div >
                                {
                                    this.state.depoNotification.accounts.map((account, index) => {
                                        return (
                                            <div className="communication-address mt-10 pr" key={index}>
                                                {
                                                    this.state.depoNotification.accounts.length > 1 &&
                                                    <span
                                                        className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                                        onClick={() => this.removeDepoAccount(index)}
                                                    >
                                                        <CloseIcon />
                                                    </span>
                                                }
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="email"
                                                    type="text"
                                                    value={account.email}
                                                    placeholder="@ EMAIL ID"
                                                    onChange={(e) => { this.handleNotificationAccountChange("depo", "email", e.target.value, index)}}
                                                />
                                                <Input
                                                    containerClassName="mb-10"
                                                    name="contactNo"
                                                    type="number"
                                                    value={account.sms}
                                                    placeholder="&#9993; SMS"
                                                    onChange={(e) => { this.handleNotificationAccountChange("depo", "sms", e.target.value, index) }}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <Button value="+ Add new communication account" onClick={this.addDepoAccount} className="add-communication-button mt-30" />
                        </div>
                    </section>
                    </Fragment>}
                </section>
                
                <div className="wt-200 ml-25 mt-50">
                    <Button value="Save Trip" onClick={this.saveTrip} />
                </div>
            </Sticky>
        );
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: GET_GPS_VENDORS });
        dispatch({ type: GET_OPERATOR_TYPE });
        dispatch({ type: GET_VEHICLE_TYPES });
        dispatch({ type: GET_VEHICLE_BODY_TYPES });
    }
}

const mapStateToProps = ({trips}) => {
    return {
        locations: trips.locations.data,
        templateUrl: trips.templateLink,
        submitting: trips.addTrip.submitting,
        providers: trips.providers.data || [],
        gpsVendorType: trips.gpsVendors || [],
        tat: trips.tat || 2,
        vehicleTypes: trips.vehicleTypes || [],
        vehicleBodyTypes: trips.vehicleBodyTypes || [],
        seekers: trips.seekers.data || [],
        operatorTypes: trips.operatorTypes || [],
        routes: trips.routeCode || [],
        vehicleList: trips.vehicleList.vehicles_list || []
    }
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(SecondaryAddTrip);

export default connect(mapStateToProps)(WrappedContainer);
