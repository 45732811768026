import { Fragment, useState } from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
import {
    advertising_cookies,
    analytics_and_customization_cookies,
    essential_website_cookies,
    performance_and_functionality_cookies
} from '../CookiePolicy/data';
import { getCookieV3, setCookieV3 } from 'Utils/storage';
const data = {
    "ad_personalization": "denied",
    "ad_storage": "denied",
    "ad_user_data": "denied",
    "analytics_storage": "denied",
    "functionality_storage": "denied",
    "personalization_storage": "denied",
    "security_storage": "granted",
    "social_storage": "denied",
    "unclassified_storage": "denied"
}
const CookiePreferencesModal = (props) => {
    const {
        onClose = () => { }
    } = props;

    const [preferences, setPreferences] = useState(() => {
        return getCookieV3('teg_default_consents') || data;
    });


    const handleToggle = (type) => {

        if (type === 2) {
            setPreferences((prev) => ({
                ...prev,
                functionality_storage: prev.functionality_storage === 'denied'
                    ? "granted"
                    : "denied",
            }));
        }
        if (type === 3) {
            setPreferences((prev) => ({
                ...prev,
                analytics_storage: prev.analytics_storage === 'denied'
                    ? "granted"
                    : "denied",
                personalization_storage: prev.personalization_storage === 'denied'
                    ? "granted"
                    : "denied",
            }));
        }
        if (type === 4) {
            setPreferences((prev) => ({
                ...prev,
                ad_personalization: prev.ad_personalization === 'denied'
                    ? "granted"
                    : "denied",
                ad_storage: prev.ad_storage === 'denied'
                    ? "granted"
                    : "denied",
                ad_user_data: prev.ad_user_data === 'denied'
                    ? "granted"
                    : "denied",
            }));
        }
        if (type === 5) {
            setPreferences((prev) => ({
                ...prev,
                social_storage: prev.social_storage === 'denied'
                    ? "granted"
                    : "denied",
            }));
        }
        if (type === 6) {
            setPreferences((prev) => ({
                ...prev,
                unclassified_storage: prev.unclassified_storage === 'denied'
                    ? "granted"
                    : "denied",
            }));
        }

    };

    const handleDeclineAll = () => {
        setCookieV3('teg_default_consents',JSON.stringify(data) ,600,".transporteg.com");
        setCookieV3('teg_default_consents_accepted', true ,600,".transporteg.com");
        onClose()
    }

    const handleSaveAndAccept = () => {
        setCookieV3('teg_default_consents', JSON.stringify(preferences) ,600,".transporteg.com");
        setCookieV3('teg_default_consents_accepted', true ,600,".transporteg.com");
        onClose()
    };

    return (
        <Fragment>
            <div className="cookie-modal-overlay">
                <div className="cookie-modal">
                    <h2
                        className="cookie-modal-title"
                    >
                        Preferences
                    </h2>
                    <div
                        className="cookie-modal-content"
                    >
                        <p>
                            We use different types of cookies to optimize your experience on our website.
                            Click on the categories below to learn more about their purposes.
                            You may choose which types of cookies to allow and can change your preferences at any time.
                            Remember that disabling cookies may affect your experience on the website.
                            You can learn more about how we use cookies by visiting our
                            <Link
                                className="cookie-policy-link"
                                to={'/legal/cookie-policy'}
                            >
                                Cookie Policy
                            </Link>.
                        </p>
                        <div className="cookie-options">

                            <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input type="checkbox" checked disabled />
                                    {`Essential Cookies (${essential_website_cookies.length})`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These cookies are necessary to the core functionality of our website and some of its features, such as access to secure areas.
                                </p>
                            </div>

                            <TableInfo
                                tableData={essential_website_cookies}
                                sectionLabel="Essential website cookies"
                            />
                            {/* <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input
                                        type="checkbox"
                                        checked={preferences.functionality_storage === 'granted'}
                                        onChange={() => {
                                            handleToggle(2)
                                        }}
                                    />
                                    {`Performance and Functionality Cookies  (${performance_and_functionality_cookies.length})`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These cookies are used to enhance the performance and functionality of our websites but are nonessential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.
                                </p>
                            </div>
                            <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input
                                        type="checkbox"
                                        checked={
                                            preferences.analytics_storage === 'granted' ||
                                            preferences.personalization_storage === 'granted'
                                        }
                                        onChange={() => {
                                            handleToggle(3)
                                        }}
                                    />
                                    {`Analytics and Customization Cookies (${analytics_and_customization_cookies.length})`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.
                                </p>
                            </div>
                            <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input
                                        type="checkbox"
                                        checked={
                                            preferences.ad_personalization === 'granted' ||
                                            preferences.ad_storage === 'granted' ||
                                            preferences.ad_user_data === 'granted'
                                        }
                                        onChange={() => {
                                            handleToggle(4)
                                        }}
                                    />
                                    {`Advertising Cookies (${advertising_cookies.length})`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These cookies are used to make advertising messages more relevant to you. They prevent the same ad from continuously reappearing, ensure that ads are properly displayed for advertisers, and in some cases select advertisements that are based on your interests.
                                </p>
                            </div>
                            <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input
                                        type="checkbox"
                                        checked={preferences.social_storage === 'granted'}
                                        onChange={() => {
                                            handleToggle(5)
                                        }}
                                    />
                                    {`Social Networking Cookies (0)`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These cookies enable you to share our website's content through third-party social networks and other websites. These cookies may also be used for advertising purposes.
                                </p>
                            </div>
                            <div
                                className='cookie-info-card-1'
                            >
                                <label
                                    className='label-1'
                                >
                                    <input
                                        type="checkbox"
                                        checked={preferences.unclassified_storage === 'granted'}
                                        onChange={() => {
                                            handleToggle(6)
                                        }}
                                    />
                                    {`Unclassified Cookies (0)`}
                                </label>
                                <p
                                    className='description-1'
                                >
                                    These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.
                                </p>
                            </div> */}
                        </div>
                    </div>
                    <div className="cookie-buttons">
                        <button onClick={handleDeclineAll} className="decline">Decline All</button>
                        <button onClick={handleSaveAndAccept} className="accept">Save & Accept</button>
                    </div>
                    <button className="close" onClick={onClose}>&times;</button>
                </div>
            </div>
        </Fragment>
    )
}

export default CookiePreferencesModal


 const TableInfo = (props) => {

    const {
      tableData = [],
      sectionLabel = "No Data"
    } = props;
  
  
    if (
      Array.isArray(tableData) &&
      tableData.length <= 0
    ) {
      return <Fragment>
        <div
          className='info-card-1'
        >
          <h5
            className='info-card-1-message'
          >
            {`No information is collected under the ${sectionLabel} section`}
          </h5>
        </div>
      </Fragment>
    }
  
    return (
      <div className="table-container-1">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purpose</th>
              <th>LifeSpan</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>{row.purpose}</td>
                <td>{row.lifespan}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };