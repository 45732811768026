import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';

import Signin from "Pages/Signin";
import TripDetail from "./pages/Trips/TripDetail";
import Trips from "./pages/Trips/TripsListing";
import TripTracking from "./pages/Trips/TripTracking";
import ShareTripTracking from "./pages/Trips/ShareTripTracking";
import AddTrip from "Pages/Trips/AddTrip";
import SecondaryAddTrip from "Pages/Trips/SecondaryAddTrip";
import Dashboard from 'Pages/Dashboard';
import Audit from "Pages/Audit";

import Analytics from 'Pages/Analytics';
import Transporters from 'Pages/Transporters';
import Settings from 'Pages/Settings';
import Holistics from 'Pages/Holistics';
// import MileStone from 'Pages/MileStone';
import { USER_TYPE } from 'Constants/commonConstants';

import { clearStorage, deleteToken, getCompanyType, getUserType } from 'Utils/storage';
// import ChangePassword from 'Containers/ChangePassword';
import ForgotPassword from 'Containers/ForgotPassword';

import App from './App';
import MultiModelTracking from "Pages/MultiModelTracking/MultiModelTracking";
import MileStone from "Pages/Flow/MileStone";
import ResetPassword from "Components/LoginForm/ResetPassword";
import ChangePassword from "Components/LoginForm/ChangePassword";
import CookiePolicy from "Pages/Common/CookiePolicy/index";
const userType = getUserType();

const AUDIT_USER_ACCESS = [
  Holistics,
  ChangePassword,
  // ForgotPassword,
  ResetPassword
];

const ZONE_USER_ACCESS = [
  Signin,
  Dashboard,
  Analytics,
  Holistics,
  ChangePassword,
  // ForgotPassword,
  TripTracking,
  Trips,
  TripDetail,
  ResetPassword
];

const RedirectToDashboard = () => <Redirect to={'/dashboard'} />;

class ProtectedRoute extends Component {
  render() {
    const { component, path } = this.props;
    if (userType === USER_TYPE.AUDIT && AUDIT_USER_ACCESS.indexOf(component) <= -1) {
      return (
        <Redirect to={'/holistics'} />
      )
    } else if (userType === USER_TYPE.ZONE && ZONE_USER_ACCESS.indexOf(component) <= -1) {
      return (
        <Redirect to={'/dashboard'} />
      )
    }
    return (
      <Route {...this.props} />
    )
  }
};

export default class Routes extends Component {
  render() {
    console.log("===> Redirected")
    //window.location.pathname.indexOf('/trips') > -1 ?  '' : window.location.pathname.search('/trip/') > -1 ? '' : clearStorage();
    // if(window.location.pathname == '/'){
    //   deleteToken();
    //   clearStorage();
    // }

    return (
      <App>
        <Switch>
          <Route exact path="/" component={Signin} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/eagle" component={Analytics}/>
          <ProtectedRoute exact path="/transporters" component={Transporters}/>
          <ProtectedRoute exact path="/settings" component={Settings}/>
          {/* <ProtectedRoute exact path="/milestone" component={MileStone}/> */}
          <ProtectedRoute exact path="/milestone" component={MileStone}/>
          <ProtectedRoute exact path="/trips" component={Trips}/>
          <ProtectedRoute exact path="/trip/:id" component={TripDetail}/>
          <ProtectedRoute exact path="/tracking/:id" component={TripTracking}/>
          <ProtectedRoute path="/tracking-public/:id" component={ShareTripTracking}/>
          <ProtectedRoute path="/tracking-public-mobile/:id" component={ShareTripTracking}/>
          <ProtectedRoute path="/add-trip/" component={AddTrip}/>
          <ProtectedRoute path="/secondary-add-trip" component={SecondaryAddTrip} />
          <ProtectedRoute exact path="/holistics" component={Holistics} />
          {/* <Route exact path="/changePassword" component={ChangePassword} /> */}
          {/* <Route exact path="/forgotPassword" component={ForgotPassword} /> */}
          {/* <ProtectedRoute exact path="/map-display" component={MapDisplay} /> */}
          {/* <Route exact path="/changePassword" component={ChangePassword} /> */}
          {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
          <Route exact path="/audit" component={Audit} />
          <ProtectedRoute path={'/multiModelTracking/:fnrNo'} component={MultiModelTracking} />
          <ProtectedRoute path={'/forgot-password'} component={ResetPassword} />
          <ProtectedRoute path={'/changePassword'} component={ChangePassword} />
          <Route exact path="/legal/cookie-policy" component={CookiePolicy} />
          <ProtectedRoute path={'/*'} component={RedirectToDashboard} />
        </Switch>
      </App>
    )
  }
}
