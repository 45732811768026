import { Fragment } from 'react'
import './index.scss'
import { advertising_cookies, analytics_and_customization_cookies, essential_website_cookies, performance_and_functionality_cookies } from './data'
const CookiePolicy = () => {
  return (
    <Fragment>
      <div className="cookie-policy">
        <h1 className="title">COOKIE POLICY</h1>
        <p className="effective-date"><strong>Effective Date:</strong> March 19, 2025 </p>
        <h2>1. Introduction?</h2>
        <p>
          Caliper Business Solution Pvt Ltd ("Company," "We," "Us," or "Our") operates the website
          <a
            className='company_link'
            href={window.location.origin}
            target="_blank"
            rel="noopener noreferrer"
          >
           {window.location.origin}
          </a> ("Website").
          This Cookie Policy explains how We use cookies and similar tracking technologies when You visit Our Website.
          <div></div>
          We use cookies to enhance Your experience and analyze Website traffic. However, We do not save any 
          <span className = {"fontBold"}  > personal information </span>
          .
        </p>

        <h2>2. What Are Cookies?</h2>
        <p>
          Cookies are small text files placed on Your device by websites that You visit.
          They help websites remember Your preferences, enhance user experience, and provide insights into Website performance and user behavior.
        </p>

        <h2>3. Types of Cookies We Use</h2>
        <ul>
          <li><strong>Essential Cookies:</strong> Necessary for the proper functioning of Our Website.</li>
          <li><strong>Analytical Cookies:</strong> Help Us understand how visitors interact with the Website.</li>
          <li><strong>Functional Cookies:</strong> Allow the Website to remember choices You make.</li>
          <li><strong>Marketing Cookies:</strong> Track visitors across websites to display relevant ads.</li>
        </ul>

        <h2>4. How We Use Cookies</h2>
        <ul>
          <li>Improve Website functionality and performance.</li>
          <li>Analyze Website traffic and visitor behavior.</li>
          <li>Provide personalized content and ads.</li>
          <li>Enhance security and prevent fraudulent activities.</li>
        </ul>

        <h2>5. Managing Cookies</h2>
        <p>
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.

          The Cookie Consent Manager can be found in the notification banner and on our Website. If you choose to reject cookies, you may still use our Website though your access to some functionality and areas of our Website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.

          The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):
        </p>

        <Fragment>
          <div>
            <div>
              <h4
                className='title-1'
              >
                Essential website cookies:
              </h4>
              <p
                className='description-1'
              >
                These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.
              </p>
              <TableInfo
                tableData={essential_website_cookies}
                sectionLabel="Essential website cookies"
              />
            </div>
            <div>
              <h4
                className='title-1'
              >
                Performance and functionality cookies:
              </h4>
              <p
                className='description-1'
              >
                These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.
              </p>
              <TableInfo
                tableData={performance_and_functionality_cookies}
                sectionLabel="Performance and functionality cookies"
              />
            </div>
            <div>
              <h4
                className='title-1'
              >
                Analytics and customization cookies:
              </h4>
              <p
                className='description-1'
              >
                These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
              </p>
              <TableInfo

                tableData={analytics_and_customization_cookies}
                sectionLabel="Analytics and customization cookies"
              />
            </div>
            <div>
              <h4
                className='title-1'
              >
                Advertising cookies:
              </h4>
              <p
                className='description-1'
              >
                These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.
              </p>
              <TableInfo
                tableData={advertising_cookies}
                sectionLabel="Advertising cookies"
              />
            </div>
          </div>
        </Fragment>


        <h2>6. Third-Party Cookies</h2>
        <p>
          Some third-party services We use (such as analytics tools or advertising networks) may place cookies on Your device.
          We do not control these cookies, and their use is subject to the third party’s privacy policy.
        </p>

        <h2>7. Changes to This Policy</h2>
        <p>
          We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If You have any questions about this Cookie Policy, please contact Us at:
        </p>
        <p>
          <strong>Caliper Business Solution Pvt Ltd</strong><br />
          Email: info@caliperbusiness.com <br />
          Website: <a href="https://www.caliperone.com" target="_blank" rel="noopener noreferrer">www.caliperone.com</a>
        </p>
      </div>
    </Fragment>
  )
}
export default CookiePolicy





export const TableInfo = (props) => {

  const {
    tableData = [],
    sectionLabel = "No Data"
  } = props;


  if (
    Array.isArray(tableData) &&
    tableData.length <= 0
  ) {
    return <Fragment>
      <div
        className='info-card-1'
      >
        <h5
          className='info-card-1-message'
        >
          {`No information is collected under the ${sectionLabel} section`}
        </h5>
      </div>
    </Fragment>
  }

  return (
    <div className="table-container-1">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Purpose</th>
            <th>LifeSpan</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.name}</td>
              <td>{row.purpose}</td>
              <td>{row.lifespan}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};